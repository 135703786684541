// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import ClientWebsocketService from '@/helpers/websocket'
import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  LOGOUT_SESSION,
  SET_USER,
} from '../../@constants/mutations'

const defaultState = {
  token: localStorage.getItem('token') || '',
  user: JSON.parse(localStorage.getItem('user') || '{}') || '',
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    // eslint-disable-next-line consistent-return
    getCurrentUser: state => state?.user || JSON.parse(localStorage.getItem('user') || '{}') || {},
  },
  mutations: {
    SET_USER_FIELD(state, payload) {
      state.user[payload.field] = payload?.value
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    [AUTH_ERROR](state) {
      state.staus = 'error'
    },
    [AUTH_LOGOUT](state) {
      state.token = ''
      state.user = ''
    },
    [AUTH_REQUEST](state) {
      state.status = 'loading'
    },
    async [AUTH_SUCCESS](state, payload) {
      return new Promise(resolve => {
        const { token, user } = payload
        state.status = 'success'
        state.token = token
        state.user = user
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))

        resolve()
      })
    },
    [SET_USER](state) {
      if (localStorage.getItem('user')) {
        state.user = JSON.parse(localStorage.getItem('user') || '{}')
      }
    },
  },
  actions: {
    REFRESH_USER_DATA: async ({ commit }) => {
      const token = localStorage.getItem('token')
      if (!token) return false

      const params = {
        fields_load: fields.SINGLE_USER,
      }
      try {
        axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
        const resp = await axiosIns.get('1/users/me', { params })
        await commit(AUTH_SUCCESS, { token, user: resp.data.data.item })
        return resp
      } catch (err) {
        commit(AUTH_LOGOUT)
        return err
      }
    },
    // eslint-disable-next-line no-unused-vars
    GET_USER_BY_TOKEN: ({ commit }, token) => new Promise((resolve, reject) => {
      axiosIns.get(`public/1/auth/userInfo/${token}`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    SET_USER_PASSWORD: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { token, newPassword } = payload
      axiosIns.post(`public/1/auth/setPassword/${token}`, newPassword)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    RESET_USER_PASSWORD: ({ commit }, userId) => new Promise((resolve, reject) => {
      axiosIns.post(`1/users/${userId}/resetPassword`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [AUTH_REQUEST]: ({ commit }, payload) => new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      const data = { email: payload.userEmail, password: payload.password }
      axiosIns.post('public/1/auth/login', data)
        .then(async resp => {
          const { token } = resp.data

          axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`

          if (!ClientWebsocketService.getInstance('CHAT')) {
            const ws = new ClientWebsocketService('CHAT')
            ws.connect({
              url: `wss://r.saleswizardapp.com/socketer/ws/user/${!window.location.href.includes('https') ? 'd1.saleswizardapp.com' : window.location.host}`,
              token,
              useInitMessageAsHeaders: true,
            })
          }

          axiosIns.get('1/users/me', { params: { fields_load: fields.SINGLE_USER } })
            .then(currentUser => {
              commit(AUTH_SUCCESS, { token, user: currentUser.data?.data?.item })
              resolve(token)
            })
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          reject(err)
        })
    }),
    [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      axiosIns.post('1/auth/logout')
        .then(() => {
          ClientWebsocketService.instances.forEach(instance => {
            // eslint-disable-next-line no-unused-expressions
            instance?.closeAndDelete()
          })

          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [LOGOUT_SESSION]: ({ commit }, session) => new Promise((resolve, reject) => {
      axiosIns.post(`1/auth/logout/${session}`)
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
