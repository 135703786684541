<!--eslint-disable-->

<template>
  <div class="email-application">
    <b-form
      id="compose-mail"
      class="mb-2"
    >
      <!--      Thread      -->
      <div class="compose-mail-form-field align-items-center">
        <label
          for="email-to"
          class="form-label text-capitalize text-nowrap"
        >{{ $t('SelectContacts') }}: </label>

        <sw-select class="mb-0 mt-50">
          <v-select
            v-model="contactThread"
            :options="modalConfig.allowThreads && modalConfig.allowThreads.length ? modalConfig.allowThreads :contactThreadList"
            label="name"
            :filterable="false"
            :placeholder="$t('SelectContacts')"
            @search="(e, is) => {
              if (modalConfig.allowThreads && modalConfig.allowThreads.length) return null
              else loadThreads(e, is)
            }"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>
            <template #option="{ name, contact, offersTotal }">
              <div class="d-flex align-items-center">
                <avatar
                  :user="contact"
                  class="mr-25"
                />  - {{ name }}
                <feather-icon
                  v-if="offersTotal >= system.contactThreadOffersLimit"
                  v-b-tooltip
                  icon="AlertCircleIcon"
                  class="ml-50"
                  :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
                />
              </div>
            </template>

            <template #selected-option="{ name, contact }">
              <div class="d-flex">
                <avatar
                  :user="contact"
                  class="mr-25"
                /> – {{ name }}
              </div>
            </template>
          </v-select>
        </sw-select>
      </div>

      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label text-capitalize text-nowrap"
        >{{ $t('To') }}: </label>
        <sw-select  class="mb-0 mt-50">
          <v-select
            id="email-to"
            v-model="mail.sentTo"
            class="flex-grow-1 email-to-selector"
            :options="userOptions || []"
            label="firstName"
            :get-option-key="option => option.type + ' ' + option.id"
          >
            <template slot="no-options">
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ avatar, firstName, lastName, type }">
              <b-avatar
                size="sm"
                :src="avatar"
                :text="(firstName || '').slice(0,1).toUpperCase() + (lastName || '').slice(0,1).toUpperCase()"
              />
              <span class="ml-50 pt-25">{{ firstName + ' ' + lastName | truncate(26) }}
                <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
              </span>
            </template>

            <template #selected-option="{ avatar, firstName, lastName, type }">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="avatar"
                :text="(firstName || '').slice(0,1).toUpperCase() + (lastName || '').slice(0,1).toUpperCase()"
              />
              <span class="ml-50 pt-25">
                {{ firstName + ' ' + lastName | truncate(26) }}
                <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
              </span>
            </template>
          </v-select>
        </sw-select>
      </div>
      <!-- Field: BW -->
      <div class="compose-mail-form-field">
        <label
          for="email-bw"
          class="form-label text-capitalize text-nowrap"
        >{{ $t('CC') }}: </label>
        <v-select

          id="email-dw"
          v-model="mail.sentToUsers"
          class="flex-grow-1 email-to-selector mb-0 mt-25"
          :options="userOptions || []"
          :selectable="option => (mail.sentTo ? option.id !== mail.sentTo.id : true)"
          label="firstName"
          :get-option-key="option => option.type + ' ' + option.id"
          multiple
        >
          <template slot="no-options">
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ avatar, firstName, lastName, type }">
            <b-avatar
              size="sm"
              :src="avatar"
              :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
            />
            <span class="ml-50 pt-25">{{ firstName + ' ' + lastName | truncate(26) }}
              <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
            </span>
          </template>

          <template #selected-option="{ avatar, firstName, lastName, type }">
            <b-avatar
              size="sm"
              class="border border-white"
              :src="avatar"
              :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
            />
            <span class="ml-50 pt-25">
              {{ firstName + ' ' + lastName | truncate(26) }}
              <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
            </span>
          </template>
        </v-select>
      </div>
      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label
          style="word-break: keep-all"
          for="email-subject"
        >{{ $t('Title') }}: </label>
        <b-form-input
          id="email-subject"
          v-model="mail.subject"
        />
      </div>
      <!-- Field: Templates -->
      <div class="compose-mail-form-field">
        <label
          for="email-template"
          class="form-label"
        >{{ $t('EmailTemplate') }}: </label>
        <v-select
          id="email-template"
          v-model="mail.template"
          label="name"
          class="flex-grow-1 email-to-selector"
          :options="options.emailTemplates"
          @input="changeContent"
          @open="loadTemplates"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ name }">
            <span class="ml-50"> {{ name }}</span>
          </template>

          <template #selected-option="{ name }">
            <span class="ml-50"> {{ name }}</span>
          </template>
        </v-select>
      </div>

      <!-- Field: AI ON/OFF -->
      <div
        v-if="checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE]) && emailConfig && emailConfig.enabled"
        class="d-flex flex-row flex-wrap justify-content-start align-items-center compose-mail-form-field py-50"
      >
        <label
          for="isOn-ai"
          class="form-label mb-0"
        >
          {{ $t('UseGeneratedAIMessage') }}:
        </label>

        <b-form-checkbox
          id="isOn-ai"
          v-model="useGeneratedAIMessage"
          class="form-label ml-50"
          variant="primary"
          switch
        />

        <p
          v-if="!useGeneratedAIMessage"
          class="text-primary mb-0"
          style="opacity: .8"
        >
          <feather-icon icon="ArrowLeftIcon" />

          {{ $t('EnableAnswerGenerator') }}
        </p>
      </div>

      <!-- Help Prompts -->
      <div
        v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE])"
        class="mb-1 compose-mail-form-field flex-column align-items-start py-1"
      >
        <label class="mb-25">
          {{ `${$t('HelpPrompts')}` }}
        </label>

        <div
          class="d-flex flex-wrap"
          style="row-gap: .5rem; column-gap: .5rem"
        >
          <b-button
            v-for="(name, index) in Object.keys(AI_PROMPTS_EMAIL)"
            :key="index"
            variant="outline-primary"
            size="sm"
            class="d-flex align-items-center"
            style="column-gap: .34rem"
            @click="mail.message = AI_PROMPTS_EMAIL[name]"
          >
            <feather-icon icon="StarIcon" />

            <p class="mb-0">
              {{ $t(`ai.helpPrompts.${name}`) }}
            </p>
          </b-button>
        </div>
      </div>

      <div
        class="compose-mail-form-field d-flex flex-column align-items-start w-100 mt-50"
        style="row-gap: .5rem"
      >
        <!--   AI content -->
        <div class="position-relative w-100">
          <div>
            <label for="quil-content">
              <template v-if="!useGeneratedAIMessage">
                {{ `${$t('MessageContent')}` }}
              </template>

              <template v-else>
                <span class="text-primary">{{ $t('EnterQueryForAIBgenerator') }}</span>
              </template>

              <!--              <template v-if="useGeneratedAIMessage">-->
              <!--                / <span class="text-primary">{{ $t('AIGeneration') }}</span>-->
              <!--              </template>-->
            </label>

            <!-- Field: Message - Quill Editor -->
            <div class="message-editor">
              <div class="position-relative">
                <quill-editor
                  id="quil-content"
                  v-model="mail.message"
                  :options="{ ...editorOption, placeholder: $t('MessageContent') }"
                />

                <div
                  id="quill-toolbar"
                  class="d-flex border-bottom-0"
                >
                  <small
                    class="textarea-counter-value rounded d-flex align-items-center float-right"
                    style="justify-self: end"
                    :class="mail.message.length >= 20000 ? 'bg-danger' : 'bg-primary'"
                  >
                    <span class="char-count">{{ mail.message.length }} </span> / 20000
                  </small>

                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-link" />
                  <button class="ql-code" />
                  <b-button
                    v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE])"
                    size="sm"
                    variant="primary"
                    :disabled="mail.message.length > 20000 || mail.message.length === 0 || !useGeneratedAIMessage"
                    style="width: max-content"
                    class="ml-50"
                    @click="aiGeneration"
                  >
                    {{ $t('GenerateMessage') }} AI
                  </b-button>
                </div>
              </div>

              <div
                v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE])"
                class="w-100 mt-50 position-relative"
              >
                <label
                  for="sms-content"
                  class="mb-50"
                >
                  {{ $t('AIResponseGenerated') }}
                </label>

                <div
                  class="border rounded-lg px-2 py-1 position-relative"
                >
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    v-if="aiResponseTyped.length"
                    v-html="aiResponseTyped"
                  />

                  <p
                    v-else
                    class="text-primary text-center h5 mb-0"
                  >
                    {{ $t('NoAnswer') }}
                  </p>

                  <small
                    class="float-right"
                  >
                    <span class="char-count" />
                  </small>

                  <b-badge
                    variant="primary"
                    class="position-absolute mt-1 position-right-0 mr-75"
                    style="border-top-left-radius: 0; border-top-right-radius: 0; font-size: .6rem;"
                  >
                    {{ $t('UsedUp') }}:
                    {{ totalTokens }}
                  </b-badge>
                </div>

                <b-overlay
                  :show="!useGeneratedAIMessage"
                  no-wrap
                >
                  <template #overlay>
                    <span class="text-primary">
                      {{ $t('EnableAnswerGenerator') }}
                    </span>
                  </template>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>

    <DragDropUpload
      ref="dragdropupload"
      :is-refresh="false"
      :max-all-files-size="15"
      class="px-md-1"
      @onDeleteFiles="mail.files = []"
      @onChangeFiles="changeFiles"
    />

      <b-alert variant="primary" show v-if="templateFiles && templateFiles.length" class="mx-1 mt-25">
          <div class="alert-heading">{{ $t('TemplateFiles') }}</div>
          <div class="alert-body">
              <documents-list :documents="templateFiles"
                              list-all
              />
          </div>
      </b-alert>

      <sw-select class="px-1 pt-25" :name="$t('PlanedSendDate')">
          <sw-select-fpr
                  :is-empty="mail.scheduledAt"
                  @clear="mail.scheduledAt = '';"
          >
              <flat-pickr
                      ref="timeRange"
                      v-model="mail.scheduledAt"
                      class="form-control"
                      :config="{ dateFormat: 'd-m-Y H:i', enableTime: true, locale: getCalendarLocale($i18n.locale), minDate: new Date() }"
              />
          </sw-select-fpr>
      </sw-select>

    <!--  Footer  -->
    <div
      class="d-flex justify-content-end p-1 mt-1 border-top"
      :class="{ 'justify-content-between': useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE]) && checkRequiredPermissions([$roles.CHAT_GPT_REPORT_ADD]) }"
    >
      <b-button
        v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE]) && checkRequiredPermissions([$roles.CHAT_GPT_REPORT_ADD])"
        variant="danger"
        size="sm"
        class="mr-50"
        :disabled="isAiLoading || !useGeneratedAIMessage || !aiResponseTyped.length"
        @click="openReportModal"
      >
        {{ $t('ReportAIMessage') }}
      </b-button>

      <div>
        <b-button
          size="sm"
          variant="secondary"
          class="mr-50"
          @click="onModalClose"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          :disabled="mail.message.length >= 20000 || !(mail.message.length !== 0) || !mail.sentTo || !mail.subject.length"
          @click="onCreateEmail"
        >
          {{ !useGeneratedAIMessage ? okBtn : $t('SendAnAIMessage') }}
        </b-button>
      </div>
    </div>

    <sw-info-overlay
      :name="`${$t('MessageIsBeingGenerated')}…`"
      :show="isAiLoading"
    >
      <template #icon>
        <b-spinner
          variant="primary"
          small
          style="margin-top: .3rem"
        />
      </template>
    </sw-info-overlay>
  </div>
</template>

<script>
import { BForm, BFormInput } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import {
  ADD_MODAL,
  CLOSE_MODAL,
  CREATE_MAIL,
  GEN_OPENAI_RESPONSE_V35,
  GET_FOOTER,
  GET_OPEN_AI_CONFIG_ALL,
  GET_TEMPLATES,
  SET_MODAL_DATA,
  SHOW_MODAL,
} from '@/@constants/mutations'
import { THREADS_SEARCH } from '@/@constants/fields'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'
import DocumentsList from '@/views/components/DocumentsList.vue'
import DragDropUpload from '../../components/DragDropUpload.vue'

export default {
  name: 'MailModal',
  components: {
    DocumentsList,
    DragDropUpload,

    // BSV
    BForm,
    BFormInput,

    // 3rd Party
    quillEditor,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
  },
  props: ['okBtn'],
  data: () => ({
    isAiLoading: false,

    typingTime: 10,
    emailConfig: null,

    ranks: [],
    mail: {
      sentTo: '',
      subject: '',
      message: '',
      scheduledAt: '',
      template: '',
      files: [],
      sentToUsers: [],
    },
    footer: '',
    editorOption: {
      modules: {
        toolbar: '#quill-toolbar',
      },
    },
    options: {
      emailTemplates: [],
    },
    selectedThread: null,

    templateFiles: [],
    contactThreadList: [],
    contactThread: null,

    useGeneratedAIMessage: false,

    aiSource: 'EMAIL',
    aiMessage: '',
    aiResponse: '',
    aiResponseTyped: '',
    totalTokens: 0,

    aiModal: null,
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      system: 'system/getSettings',
      AI_PROMPTS_EMAIL: 'ai/getPrompts.EMAIL',
      AI_TYPE_EMAIL: 'ai/getType.EMAIL',
      thread: 'contact/thread',
    }),
    userOptions: {
      get() {
        return this.selectedThread ? [
          ...this.selectedThread.assignedUsers.map(item => ({ ...item, type: 'u' })),
          ...this.selectedThread.contactThreadAssignedContacts.map(item => ({ ...item.contact, type: 'c' })),
          { ...this.selectedThread.contact, type: 'c' },
        ] : (this.contactThread?.assignedUsers || [])
      },
      set() {},
    },
  },
  mounted() {
    if (this.$store.getters['singleContact/getSelectedThread']) {
      this.selectedThread = this.$store.getters['singleContact/getSelectedThread']
    } else if (this.modalConfig && this.modalConfig.thread) {
      this.selectedThread = this.modalConfig.thread
    } else if (this.thread) {
      this.selectedThread = this.thread
    }

    if (this.modalConfig.data) {
      // eslint-disable-next-line prefer-destructuring
      this.mail.sentTo = this.modalConfig.data?.sendTo || []

      if (this.modalConfig.data?.fromMail) {
        if (this.userOptions.find(e => e.email === this.modalConfig.data.fromMail)) {
          this.mail.sentTo = this.userOptions.find(e => e.email === this.modalConfig.data.fromMail)
        }

        if (this.modalConfig.data?.subject) {
          this.mail.subject = `Re: ${this.modalConfig.data.subject.replaceAll('Re: ', '')}`
        }
      }
    }

    if (this.$route.params.threadId) {
      this.loadThread(this.$route.params.threadId, loading => { this.isMeLoading = loading })
    }

    if (this.modalConfig.allowThreads?.length) {
      // eslint-disable-next-line prefer-destructuring
      this.contactThread = this.modalConfig.allowThreads[0]
    }

    this.$store.dispatch('ranks/GET_RANKS_LIST')
      .then(() => {
        this.ranks = this.$store.getters['ranks/getRanksList']
      })

    this.$store.dispatch(`templates/${GET_FOOTER}`)
      .then(res => {
        this.footer = res.data.item.emailFooter
        this.mail.message += `
          <br /> --- <br />
          ${res.data.item.emailFooter}
        `

        if (this.modalConfig.data?.contentHtml) {
          const a = document.createElement('div')
          a.innerHTML = this.modalConfig.data?.contentHtml.includes('style') ? this.modalConfig.data?.contentHtml : this.modalConfig.data?.contentHtml.replaceAll('\n', '<br>')

          //           this.mail.message += `
          //           <br /> --- <br />
          //           <p style="border-left: 2px solid #2e2e2e">
          //           <blockquote>
          //             ${a.innerHTML}
          //           </blockquote>
          // </p>
          //         `
        }
      })
      .catch(err => {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      })

    if (this.checkRequiredModule('chatGptModule') && this.checkRequiredPermissions([this.$roles.CHAT_GPT_USE])) {
      this.$store.dispatch(`ai/${GET_OPEN_AI_CONFIG_ALL}`)
        .then(res => {
          const emailConfig = res.find(i => i.type === 'EMAIL')
          if (emailConfig) { this.emailConfig = emailConfig }
        })
    }

    this.loadTemplates()

    if (!this.threadId && !this.modalConfig.threadId) {
      this.loadThreads('', loading => { this.isMeLoading = loading })
    } else {
      this.loadThread(this.threadId || this.modalConfig.threadId, loading => { this.isMeLoading = loading })
    }

    this.$store.dispatch(`swModal/${ADD_MODAL}`, {
      title: this.$i18n.t('ReportAIResponse'),
      component: 'm-ai-report',
      data: { content: this.aiResponse },
      hideFooter: true,
    }).then(id => this.$set(this, 'aiModal', id))
  },
  methods: {
    aiGeneration() {
      this.isAiLoading = true

      this.aiResponseTyped = ''

      if (this.mail.message) {
        this.getAiResponse(this.mail.message)
      } else {
        this.isAiLoading = false
      }
    },

    async getAiResponse(prompt) {
      this.isAiLoading = true

      if (prompt) {
        const payload = {
          system: this.AI_TYPE_EMAIL,
          content: JSON.parse(JSON.stringify(prompt)),
          source: this.aiSource,
          contactThread: this.getObjectId(this.contactThread),
        }

        this.$store.dispatch(`ai/${GEN_OPENAI_RESPONSE_V35}`, payload)
          .then(({ content, totalTokens }) => {
            this.aiResponse = content
            this.totalTokens = totalTokens

            this.typeText()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
          })
          .finally(() => {
            this.isAiLoading = false
          })
      } else {
        this.isAiLoading = false
      }
    },

    typeText() {
      let currentIndex = 0

      this.showCursor = true

      // eslint-disable-next-line no-unreachable
      const typingInterval = setInterval(() => {
        this.aiResponseTyped += this.aiResponse[currentIndex]
        currentIndex += 1

        if (currentIndex === this.aiResponse.length) {
          clearInterval(typingInterval)

          setTimeout(() => {
            this.isAiLoading = false
          }, 200)
        }
      }, this.typingTime)
    },

    changeFiles(files) {
      // this.mail.files = files
      this.mail.files.push(...files)
    },
    changeContent(template) {
      this.mail.message = template.content ?? ''
      this.mail.message += `
          <br /> --- <br /> ${this.footer}
        `
      if (this.modalConfig.data?.contentHtml) {
        const a = document.createElement('div')
        a.innerHTML = this.modalConfig.data?.contentHtml.replaceAll('\n', '<br>')

        //         this.mail.message += `
        //           <br /> --- <br />
        //           <p style="border-left: 2px solid #2e2e2e">
        //           <blockquote>
        //             ${a.innerHTML}
        //           </blockquote>
        // </p>
        //         `
      }

      this.templateFiles = template.files || []
    },
    loadTemplates() {
      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'email' })
        .then(res => {
          this.options.emailTemplates = res.data.items
        })
    },
    onModalClose() {
      this.$emit('close')
    },
    changeAssignedUser(payload) {
      if (payload) this.mail.sentTo = [payload]
      else this.mail.sentTo = []
    },
    onCreateEmail() {
      const { contactThread, useGeneratedAIMessage, aiResponse } = this
      const threadId = contactThread.id

      const emailData = {
        subject: this.mail.subject,
        contentHtml: !useGeneratedAIMessage ? this.mail.message.replaceAll('\n', '<br>') : aiResponse.replaceAll('\n', '<br>'),
        files: this.mail.files,
        scheduledAt: this.mail.scheduledAt || null,
        templateFiles: this.templateFiles || null,
      }

      if (!emailData.scheduledAt) {
        delete emailData.scheduledAt
      }

      if (typeof this.mail.sentTo === 'object') {
        if (this.mail.sentTo.type === 'c') emailData.sendToContact = this.mail?.sentTo?.id
        else emailData.sendToUser = this.mail?.sentTo?.id
      } else {
        this.showToast('danger', this.$i18n.t('ProblemOccured'))
        return
      }

      const contacts = this.mail.sentToUsers.filter(user => user.type === 'c')
      const users = this.mail.sentToUsers.filter(user => user.type === 'u')

      emailData.sendCcUsers = users.map(item => this.getId(item))
      emailData.sendCcContacts = contacts.map(item => this.getId(item))

      const payload = {
        emailData,
        threadId,
      }

      // eslint-disable-next-line no-unreachable
      this.$store.commit(`modal/${CLOSE_MODAL}`)
      this.$store.dispatch(`modal/${CREATE_MAIL}`, payload)
        .then(() => {
          this.showToast('success', this.$i18n.t('SendedSuccessfully'))

          this.$nextTick(() => this.close())
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    getId(field) {
      // eslint-disable-next-line no-nested-ternary
      return typeof field === 'object' ? (field?.id ? field.id : '') : field
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id) {
      const thread = await axiosIns(`1/contacts/threads/${id}`, { params: { fields_load: THREADS_SEARCH } })
      if (thread.data.data.item) {
        this.contactThread = thread.data.data.item
      }
    },

    openReportModal() {
      this.$store.dispatch(`swModal/${SET_MODAL_DATA}`, {
        id: this.aiModal,
        data: {
          content: this.aiResponse,
          source: this.aiSource,
          contactThread: this.contactThread,
        },
      })
        .then(() => this.$store.dispatch(`swModal/${SHOW_MODAL}`, this.aiModal))
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/base/pages/app-email.scss";
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
  form::v-deep {

    // Mail To vue-select style
    .v-select {
      .vs__dropdown-toggle {
        border: 0;
        box-shadow: none;
      }
      .vs__open-indicator {
        display: none;
      }
    }

    // Quill Editor Style
    .quill-editor {
      .ql-container.ql-snow {
        border-bottom: 0 !important;
      }
    }

    .ql-container {
      resize: vertical;
      overflow: auto;
    }
  }
</style>
