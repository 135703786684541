<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <template v-if="isOnline">
      <p-w-a-prompt />

      <component
        :is="layout"
        v-if="!$store.getters['system/getConfigLoading']"
      >
        <router-view />
      </component>

      <div
        v-else
        class="position-absolute loading-bg"
        style="left: 0; top: 0; height: 100vh; width: 100vw; background-color: #fff;"
      >
        <!--      <img-->
        <!--        :src="require('@/assets/images/core/sygnet.png')"-->
        <!--        height="70px"-->
        <!--        class="blinking-image"-->
        <!--      >-->
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>

      <!-- Global Action Modals -->
      <b-modal
        :id="modalState.modalType"
        v-model="modalState.isModalOpen"
        :title="modalState.config.title"
        :size="modalState.config.size"
        :body-class="modalState.config.bodyclass"
        :modal-class="modalState.config.modalClass"
        hide-footer
        no-close-on-backdrop
        @hidden="onCloseModal"
      >
        <component
          :is="modalState.modalType"
          v-if="modalState.isModalOpen"
          :ok-btn="modalState.config.okButtonTitle"
          :have-thread="modalState.threadId !== null"
          @close="onCloseModal"
        />
      </b-modal>

      <sw-modal
        v-for="(
          {
            id,
            title,
            component,
            data,
            visible,
            isLoading,

            hideFooter,
            okTitle,
            cancelTitle,
            size,
            onCloseRemove,
          }, index) in modals"
        :id="id"
        :key="index"
        :title="title"
        :component="component"
        :data="data"
        :visible="visible"
        :is-loading="isLoading"

        :hide-footer="hideFooter"
        :ok-title="okTitle"
        :cancel-title="cancelTitle"
        :size="size"
        :on-close-remove="onCloseRemove"
      />

      <!-- Uploaded Files Toast -->
      <b-toast
        :visible="$store.getters['uploader/getShowUploadToast']"
        variant="primary"
        solid
        :title="$t('UploadedFiles')"
        :no-auto-hide="true"
        toaster="b-toaster-bottom-right"
      >
        <vue-perfect-scrollbar style="max-height: 150px">
          <!-- Single Uploaded File -->
          <div
            v-for="(upload, index) in $store.getters['uploader/getUploadedFiles']"
            :key="`file_${index}`"
            class="uploaded-file mb-1 pr-2"
          >
            <div
              v-for="(singleFile, fileIndex) in upload.uploadedFiles"
              :key="`file_${index}_${fileIndex}`"
              class="d-flex justify-content-between align-items-center"
            >
              <span>
                <feather-icon
                  icon="FileIcon"
                  class="pr-50"
                  size="20"
                />
                {{ singleFile.name }}
              </span>
            <!--            <feather-icon icon="XIcon" />-->
            </div>
            <b-progress
              :value="upload.progress"
              size="sm"
              animated
            />
          </div>
        <!-- Single Uploaded File -->
        </vue-perfect-scrollbar>
      </b-toast>

      <b-toast
        :visible="importer.loading"
        variant="primary"
        solid
        title="Importer danych"
        :no-auto-hide="true"
        toaster="b-toaster-bottom-right"
      >
        <div class="d-flex justify-content-between w-100">
          <div>{{ $t(importer.label) }}</div>
          <div><b-spinner
            small
            variant="primary"
          /></div>
        </div>
      </b-toast>

      <!-- Scroll to top -->
      <scroll-to-top />
    </template>

    <error-no-internet-splash v-else />

    <ActionAlert v-if="currentUser && currentUser.id" />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { mapGetters } from 'vuex'

import { useCssVar, useWindowSize } from '@vueuse/core'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ShortcodesModal from '@/views/modals/ShortcodesModal.vue'
import OrganizationModal from '@/views/modals/OrganizationModal.vue'
import AddStatusModal from '@/views/modals/projects/AddStatusModal.vue'
import AddMicroTaskModal from '@/views/modals/projects/AddMicroTaskModal.vue'
import AddAdditionalInformation from '@/views/modals/contact/AddAdditionalInformation.vue'
import LinkThreadModal from '@/views/modals/emails/LinkThreadModal.vue'
import CinemaModal from '@/views/modals/CinemaModal.vue'
import ScrollToTop from '@/views/components/ScrollToTop.vue'
import AddSchedule from '@/views/modals/schedules/AddSchedule.vue'
import NewFunnelField from '@/views/modals/settings/NewFunnelField.vue'
import logo from '@/assets/images/core/sygnet.png'
import PWAPrompt from '@/components/PWAPrompt.vue'
import ClientWebsocketService from '@/helpers/websocket'
import ShortContactModal from '@/views/modals/ShortContactModal.vue'
import ActionAlert from '@/views/ActionAlert.vue'
import {
  AUTH_LOGOUT, CLOSE_MODAL, GET_SETTINGS, RELOAD_SETTINGS, SET_USER, UPDATE_NAV,
} from './@constants/mutations'

import NoteModal from './views/modals/contact/NoteModal.vue'
import MailModal from './views/modals/contact/MailModal.vue'
import SmsModal from './views/modals/contact/SmsModal.vue'
import TaskModal from './views/modals/contact/TaskModal.vue'
import ReportTaskModal from './views/modals/contact/ReportTaskModal.vue'
import ViewModal from './views/modals/ViewModal.vue'
import CalendarModal from './views/modals/CalendarModal.vue'
import ThreadModal from './views/modals/contact/ThreadModal.vue'
import NewContactDocumentModal from './views/modals/NewContactDocumentModal.vue'
import AddChecklistModal from './views/modals/contact/AddChecklistModal.vue'
import ListOfChecklistModal from './views/modals/ListOfChecklistModal.vue'
import AddProjectDocumentModal from './views/modals/projects/AddProjectDocumentModal.vue'
import ReportAIModal from './views/modals/AI/ReportAIModal.vue'
import ProcessModal from './views/modals/processes/ProcessModal.vue'
import ErrorNoInternetSplash from './views/error/ErrorNoInternet.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    ActionAlert,
    ScrollToTop,
    VuePerfectScrollbar,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    note: NoteModal,
    mail: MailModal,
    viewModal: ViewModal,
    newContactDocumentModal: NewContactDocumentModal,
    calendarModal: CalendarModal,
    thread: ThreadModal,
    sms: SmsModal,
    task: TaskModal,
    report: ReportTaskModal,
    shortcodes: ShortcodesModal,
    organization: OrganizationModal,
    addStatusModal: AddStatusModal,
    addProjectDocumentModal: AddProjectDocumentModal,
    addMicroTaskModal: AddMicroTaskModal,
    cinema: CinemaModal,
    addAdditionalInformation: AddAdditionalInformation,
    linkThreadModal: LinkThreadModal,
    addChecklistModal: AddChecklistModal,
    listOfChecklistModal: ListOfChecklistModal,
    addSchedule: AddSchedule,
    newFunnelField: NewFunnelField,
    reportAIModal: ReportAIModal,
    processModal: ProcessModal,
    contactShort: ShortContactModal,
    PWAPrompt,
    ErrorNoInternetSplash,
  },
  data: () => ({
    socket: null,
    logo,
    isOnline: navigator.onLine,
  }),
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({
      modalState: ['modal/getModalState'],
      importer: 'importer/importerData',
      modals: 'swModal/getModal.all',
      currentUser: 'auth/getCurrentUser',
    }),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },

  watch: {
    '$route.meta': {
      deep: true,
      handler(val) {
        const title = val?.pageTitle || val?.title || ''
        this.$store.dispatch('app/UPDATE_TITLE', {
          label: this.$i18n.t(`${title}`),
          title,
        })
      },
    },
  },
  beforeMount() {
    const token = localStorage.getItem('token')
    if (token) {
      this.$store.commit(`auth/${SET_USER}`)
      axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
      if (this.$route.name === 'login') this.$router.push('/')
    }
    this.$store.dispatch(`system/${GET_SETTINGS}`, { showLoading: true })
      .then(item => {
        store.dispatch(`verticalMenu/${UPDATE_NAV}`)

        if (item?.styles) {
          if (item.styles?.colors) {
            Object.keys(item.styles.colors).forEach(key => {
              document.documentElement.style.setProperty(`--theme-color-${key}`, item.styles.colors[key])
            })
          }
          if (item.styles?.sizes) {
            Object.keys(item.styles.sizes).forEach(key => {
              document.documentElement.style.setProperty(`--theme-${key}`, item.styles.sizes[key])
            })
          }
        }
      })
      .catch(err => {
        if (err.response?.data?.code === 505) {
          this.$router.push({ name: 'newer-system-available' })
        }
        if (err.response?.data?.message === 'SERVICE_NOT_EXIST' && err.response.data?.code === 503 && !window.location.href.includes('/system-not-exists')) {
          this.$router.push('/system-not-exists')
        }
      })
  },
  updated() {
    const token = localStorage.getItem('token')
    if (token) {
      axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
    }
  },
  created() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)

    window.addEventListener('keydown', this.escapeListener)

    const token = localStorage.getItem('token')
    if (token) {
      axiosIns.defaults.headers.common.Authorization = `Bearer ${token}`
    }
    if (this.$route.name !== 'login' && localStorage.getItem('token') && localStorage.getItem('user')) {
      this.setDefaultTypes()
      this.$store.dispatch('ranks/GET_RANKS_LIST')
    }
    // this.$http.interceptors.response.use(response => {
    //   const err = {
    //     response: {
    //       data: {
    //         message: 'BAD_RESPONSE',
    //       },
    //     },
    //   }
    //   if (typeof response.data === 'string') {
    //     return Promise.reject(err)
    //   }
    //   return response
    // }, err => new Promise(() => {
    //   // System not exists - SERVICE_NOT_EXIST
    //   if (err.response && ([403, 401].includes(err.response.status))) {
    //     console.log('lel')
    //     // if you ever get an unauthorized, logout the user
    //     if (localStorage.getItem('token')) {
    //       this.$store.dispatch(`auth/${AUTH_LOGOUT}`)
    //       this.$router.push('/login')
    //     }
    //     // you can also redirect to /login if needed !
    //   }
    //   throw err
    // }))
  },
  // make sure you remove the listener when the component is no longer visible
  destroyed() {
    window.removeEventListener('keydown', this.escapeListener)
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  async mounted() {
    if (this.$route.name !== 'login' && localStorage.getItem('token') && localStorage.getItem('user')) {
      this.setDefaultTypes()
      this.$store.dispatch('ranks/GET_RANKS_LIST')
    }
  },
  async beforeCreate() {
    if (localStorage.getItem('token')) {
      const ws = new ClientWebsocketService('CHAT')
      ws.connect({
        url: `wss://r.saleswizardapp.com/socketer/ws/user/${!window.location.href.includes('https') ? 'd1.saleswizardapp.com' : window.location.host}`,
        token: localStorage.getItem('token'),
        useInitMessageAsHeaders: true,
        autoReconnect: 500,
      })
    }

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    await this.$store.dispatch(`system/${GET_SETTINGS}`)
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine
    },
    escapeListener(e) {
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
        localStorage.clear()
        sessionStorage.clear()

        const cookies = document.cookie.split(';')

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i]
          const eqPos = cookie.indexOf('=')
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
        }

        this.$store.dispatch(`auth/${AUTH_LOGOUT}`)
          .finally(() => {
            if (this.$route.name !== 'login') this.$router.push('/login')
          })
      }
    },
    onCloseModal() {
      this.$store.commit(`modal/${CLOSE_MODAL}`)
    },
    setDefaultTypes() {
      this.$store.dispatch(`typeSettings/${RELOAD_SETTINGS}`)
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/sections which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = logo
    document.getElementsByTagName('head')[0].appendChild(link)

    // Set Logos
    store.dispatch(`system/${GET_SETTINGS}`)
    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)

    store.dispatch('auth/REFRESH_USER_DATA')
    // Load userdata
    // eslint-disable-next-line
    const authInterval = setInterval(() => {
      store.dispatch('auth/REFRESH_USER_DATA')
    }, 300000)

    const { width: windowWidth } = useWindowSize()

    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-slider.scss';

  .flatpickr-input:disabled {
    background-color: #efefef !important;
    cursor: unset;
  }

  .vs__open-indicator {
    transform: scale(.6) !important;
    fill: rgba(60, 60, 60, 0.5) !important;
  }

  .sidebar-content {
    padding: 1.5rem;
  }

  .dark-layout {
    & .bg-light {
      & * {color: white !important;}
      background-color: #1e2743 !important;
    }
  }

  .color-dot {
    display: inline-block;
    margin-right: 5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .dropdown-menu {
    .active {
      background-color: rgba(115, 103, 240, 0.12);
      color: #7367f0;
    }
  }
  th div, td {
    white-space: nowrap !important;
  }

  .b-avatar-group .b-avatar-group-inner {
    flex-wrap: nowrap !important;
  }

  .timeline-nav {
    & .dropdown-toggle {
      padding: 3px 5px !important;
    }
  }

  .uploaded-file {
    & > .progress {
      height: .5rem !important;
    }
  }

  .funnel {
    & .list-group-flush.cursor-move {
      min-height: 150px;
    }
  }

  .limiter {
    .v-select {
      min-width: 105px;
    }
  }

  .table-wrap-words {
    th, td {
      white-space: unset !important;
    }
  }

  //  Search input
  #inputSearch { max-width: 16rem; }

  .b-table-selectable {
    .feather {
      font-size: 1.3rem;
    }
  }

  .search-input-group {
    box-shadow: unset !important;

    .input-search, .input-group-text {
      background: unset;
      border: unset;
      border-bottom: 1px solid #d8d6de;
      border-radius: 0;

      &:focus {
        background: unset;
        border-bottom: 1px solid var(--purple);
      }
    }
  }

  th[role=columnheader] {
    white-space: nowrap !important;
    text-transform: none !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
    text-transform: none !important;
  }

  .vs--disabled {
    .vs__selected {
      color: #6e6b7b !important;
    }
  }
  .vs--single {
    &:not(.vs--open) .vs__selected + .vs__search {
      // force this to not use any space
      // we still need it to be rendered for the focus
      width: 0;
      padding: 0;
      margin: 0;
      border: none;
      height: 0;
    }

    .vs__selected-options {
      // do not allow growing
      width: 0;
    }

    .vs__selected {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }
  }
  //.vs--single {
  //  &:not(.vs--open) .vs__selected + .vs__search {
  //    // force this to not use any space
  //    // we still need it to be rendered for the focus
  //    width: 0;
  //    display: none;
  //  }
  //
  //  .vs__selected-options {
  //    // do not allow growing
  //    width: 0;
  //  }
  //
  //  .vs__selected {
  //    display: block;
  //    white-space: nowrap;
  //    text-overflow: ellipsis;
  //    max-width: 100%;
  //    overflow: hidden;
  //  }
  //
  //  .vs__dropdown-option {
  //    width: 100%;
  //    overflow: hidden;
  //    white-space: nowrap;
  //    text-overflow: ellipsis;
  //    &::after {
  //      display: none;
  //      content: '';
  //      visibility: hidden;
  //    }
  //  }
  //}

  .table-min-height {
    min-height: 15rem;
  }

  [dir=ltr] .bs-popover-auto[x-placement^=left]>.arrow:after, [dir=ltr] .bs-popover-left>.arrow:after, [dir=rtl] .bs-popover-auto[x-placement^=right]>.arrow:after, [dir=rtl] .bs-popover-right>.arrow:after {
    right: 0 !important;
  }

  .subcomponent-table {
    margin: 0 !important;
  }

  .b-table-details-no-padding {
    .b-table-details {
      & > td {
        padding: 0 !important;
        .card {
          margin-bottom: 0 !important;
          .card-body {
            padding: 0 !important;
          }
        }
      }
    }
  }

  .flatpickr-input[readonly] {
    background-color: inherit !important;
  }

  .flatpickr-input:disabled {
    background-color: #f8f8f8 !important;
  }

  .custom-checkbox {
    word-break: break-all !important;
  }

  .header-navbar .navbar-container .dropdown-menu-media {
     width: 41.5rem !important;
  }

  //html {
  //  font-size: .75rem !important;
  //}

</style>

<style lang="scss">
@import "main.css";

.tree-container > .tree-item {
  padding-left: 0 !important;
}

.tree-item {
  &.card {
    box-shadow: none !important;
  }

  .card-header {
    padding-top: .6rem !important;
    padding-bottom: .6rem !important;
  }

  .card-body {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.blinking-image {
  animation: 2s blinking infinite ease-in-out reverse;
}

@keyframes blinking {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.dragDrop, .colorPicker {
  position: relative;

  /*    First div    */
  div,
  img {
    position: relative;

    width : 240px;
    height: 120px;

    display: flex;
    justify-content: center;
  }

  /*    If img is rectangle    */
  &.rec div,
  &.rec img {
    width: 120px;
    height: 120px;
  }

  /*    Sets size    */
  div, button, input {
    width: 100%;
    height: 100%;

    max-width: 240px;
    max-height: 120px;
  }

  /*    Sets size for img    */
  img { max-height: 150px }

  /*    Add button    */
  button {
    position: absolute;

    opacity: 0;

    transition: opacity .14s ease-in-out;

    /*    Input    */
    input {
      position: absolute;

      left: 0;
      top : 0;

      cursor: pointer;

      opacity: 0;
    }

    /*    Button Hover    */
    &:hover { opacity: .5; }
  }
}

.colorPicker {
  /*    Sets size    */
  div, button, input {
    max-width: unset;
    max-height: unset;

    height: 8.5rem;

    cursor: pointer;

    opacity: 1;

    transition: opacity .14s ease-in-out;

    overflow: hidden;

    outline: none;
    padding: unset;
    border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  input:hover {
    opacity: .75;
  }
}

.sw-audio-player {
  .player-container {
    width: 200px;
  }

  .player-row {
    width: 100%;
    max-width: 84rem;
  }

  .button-div {
    flex: initial;
    padding-right: 0.75rem;
  }

  .play-button {
    width: 2rem;
  }

  .play-button-inactive {
    color: #a0aec0;
  }

  .play-button-active {
    color: #f6ad55;
  }

  .play-button-loaded {
    color: #dd6b20;
    cursor: pointer;
  }

  .progress-bar {
    flex-grow: 1;
    background-color: white;
    border-radius: 7px;
    border: 1px solid rgba(var(--theme-color-primary), .6);
    position: relative;
  }

  .overlay-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .slider {
    width: 100%;
    height: 100%;
  }

  .loading-indicator {
    color: #94bcec;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    pointer-events: none;
  }

  .time-display {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    pointer-events: none;
  }

  .elapsed-time,
  .total-time {
    font-size: 0.875rem;
    color: rgb(var(--theme-color-primary));
  }

  /* Play/Pause Button */
  .play-button{
    /*height: 45px*/
  }
  input[type="range"] {
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 0; /* iOS */
    background: transparent;
  }
  input[type="range"]:focus {
    outline: none;
  }
  ::-webkit-slider-runnable-track {
    background: #fff;
  }
  /*
   * 1. Set to 0 width and remove border for a slider without a thumb
   */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0; /* 1 */
    /*height: 40px;*/
    background: #fff;
    box-shadow: -100vw 0 0 100vw rgb(var(--theme-color-primary));
    border: none; /* 2px solid #999; */
  }
  ::-moz-range-track {
    height: 40px;
    background: #ddd;
  }
  ::-moz-range-thumb {
    background: #fff;
    height: 40px;
    width: 0; /* 20px; */
    border: none; /* 3px solid #999; */
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw rgb(var(--theme-color-primary));
    box-sizing: border-box;
  }
  ::-ms-fill-lower {
    background: rgb(var(--theme-color-primary));
  }
  ::-ms-thumb {
    background: #fff;
    border: 2px solid #999;
    height: 40px;
    width: 20px;
    box-sizing: border-box;
  }
  ::-ms-ticks-after {
    display: none;
  }
  ::-ms-ticks-before {
    display: none;
  }
  ::-ms-track {
    background: #ddd;
    color: transparent;
    height: 40px;
    border: none;
  }
  ::-ms-tooltip {
    display: none;
  }
}

.modal-fullscreen {
  margin: 5px !important;
  .modal-content {
    margin: 5px !important;
    width: 100%;
    max-height: calc(100vh - 10px) !important;
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 2px;
    bottom: 5px;
    left: 0 !important;
    width: calc(100% - 10px);
    right: 0 !important;
    height: 100vh !important;
    display: flex;
    position: fixed;
    max-height: 100vh !important;
  }
}
</style>
