var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100",class:[_vm.skinClasses],attrs:{"id":"app"}},[(_vm.isOnline)?[_c('p-w-a-prompt'),(!_vm.$store.getters['system/getConfigLoading'])?_c(_vm.layout,{tag:"component"},[_c('router-view')],1):_c('div',{staticClass:"position-absolute loading-bg",staticStyle:{"left":"0","top":"0","height":"100vh","width":"100vw","background-color":"#fff"}},[_c('div',{staticClass:"loading"},[_c('div',{staticClass:"effect-1 effects"}),_c('div',{staticClass:"effect-2 effects"}),_c('div',{staticClass:"effect-3 effects"})])]),_c('b-modal',{attrs:{"id":_vm.modalState.modalType,"title":_vm.modalState.config.title,"size":_vm.modalState.config.size,"body-class":_vm.modalState.config.bodyclass,"modal-class":_vm.modalState.config.modalClass,"hide-footer":"","no-close-on-backdrop":""},on:{"hidden":_vm.onCloseModal},model:{value:(_vm.modalState.isModalOpen),callback:function ($$v) {_vm.$set(_vm.modalState, "isModalOpen", $$v)},expression:"modalState.isModalOpen"}},[(_vm.modalState.isModalOpen)?_c(_vm.modalState.modalType,{tag:"component",attrs:{"ok-btn":_vm.modalState.config.okButtonTitle,"have-thread":_vm.modalState.threadId !== null},on:{"close":_vm.onCloseModal}}):_vm._e()],1),_vm._l((_vm.modals),function(ref,index){
        var id = ref.id;
        var title = ref.title;
        var component = ref.component;
        var data = ref.data;
        var visible = ref.visible;
        var isLoading = ref.isLoading;
        var hideFooter = ref.hideFooter;
        var okTitle = ref.okTitle;
        var cancelTitle = ref.cancelTitle;
        var size = ref.size;
        var onCloseRemove = ref.onCloseRemove;
return _c('sw-modal',{key:index,attrs:{"id":id,"title":title,"component":component,"data":data,"visible":visible,"is-loading":isLoading,"hide-footer":hideFooter,"ok-title":okTitle,"cancel-title":cancelTitle,"size":size,"on-close-remove":onCloseRemove}})}),_c('b-toast',{attrs:{"visible":_vm.$store.getters['uploader/getShowUploadToast'],"variant":"primary","solid":"","title":_vm.$t('UploadedFiles'),"no-auto-hide":true,"toaster":"b-toaster-bottom-right"}},[_c('vue-perfect-scrollbar',{staticStyle:{"max-height":"150px"}},_vm._l((_vm.$store.getters['uploader/getUploadedFiles']),function(upload,index){return _c('div',{key:("file_" + index),staticClass:"uploaded-file mb-1 pr-2"},[_vm._l((upload.uploadedFiles),function(singleFile,fileIndex){return _c('div',{key:("file_" + index + "_" + fileIndex),staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_c('feather-icon',{staticClass:"pr-50",attrs:{"icon":"FileIcon","size":"20"}}),_vm._v(" "+_vm._s(singleFile.name)+" ")],1)])}),_c('b-progress',{attrs:{"value":upload.progress,"size":"sm","animated":""}})],2)}),0)],1),_c('b-toast',{attrs:{"visible":_vm.importer.loading,"variant":"primary","solid":"","title":"Importer danych","no-auto-hide":true,"toaster":"b-toaster-bottom-right"}},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',[_vm._v(_vm._s(_vm.$t(_vm.importer.label)))]),_c('div',[_c('b-spinner',{attrs:{"small":"","variant":"primary"}})],1)])]),_c('scroll-to-top')]:_c('error-no-internet-splash'),(_vm.currentUser && _vm.currentUser.id)?_c('ActionAlert'):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }