import Vue from 'vue'
import i18n from '@/libs/i18n'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line no-unused-vars
export const showToast = (variant = 'primary', text = 'Empty message', errorLog = null) => {
  console.error(errorLog)

  const props = {
    title: variant === 'success' ? i18n.t('alerts.Success') : i18n.t('alerts.Failure'),
    icon: variant === 'success' ? 'CheckIcon' : 'XIcon',
    text,
    variant: variant === 'error' ? 'danger' : variant,
  }
  if (variant === 'primary') {
    props.title = text
    props.icon = 'CopyIcon'
    delete props.text
  }
  Vue.$toast({
    component: ToastificationContent,
    props,
  }, {
    position: 'top-center',
  })
}

export const _ = null
