<!--eslint-disable-->

<template>
  <div>
      <b-form-group>
          <b-form-checkbox
                  v-model="unsignedTask"
                  variant="primary"
                  class="custom-control-primary"
                  @change="val => {
                      if (val) contactThread = null
                      else contactThread = JSON.parse(JSON.stringify(contactThreadCopy))
                  }"
          >
              {{ $t('UnsignedTask') }}
          </b-form-checkbox>
      </b-form-group>

    <!--      Thread      -->
    <sw-select :name="$t('Thread')">
      <v-select
        v-model="contactThread"
        :options="modalConfig.allowThreads && modalConfig.allowThreads.length ? modalConfig.allowThreads : contactThreadList"
        label="name"
        :disabled="unsignedTask"
        :filterable="false"
        @search="(e, is) => {
          if (modalConfig.allowThreads && modalConfig.allowThreads.length) return null
          else loadThreads(e, is)
        }"
      >
        <template #no-options="{ search }">
          <span v-if="search.length">
            {{ $t('NoData') }}
          </span>
          <span v-else>
            {{ $t('TypeToSearch') }}
          </span>
        </template>
        <template #option="{ name, contact, offersTotal }">
          <div class="d-flex align-items-center">
            <avatar
              :user="contact"
              class="mr-25"
            />  - {{ name }}
            <feather-icon
              v-if="offersTotal >= system.contactThreadOffersLimit"
              v-b-tooltip
              icon="AlertCircleIcon"
              class="ml-50"
              :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
            />
          </div>
        </template>

        <template #selected-option="{ name, contact }">
          <div class="d-flex">
            <avatar
              :user="contact"
              class="mr-25"
            /> – {{ name }}
          </div>
        </template>
      </v-select>
    </sw-select>

    <!--    Task type-->
    <sw-select :name="$t('task.Type')">
      <v-select
        id="task-type"
        v-model="task.type"
        :options="taskType"
        item-value="id"
        item-text="name"
        label="name"
        :clearable="false"
        @input="checkRequireds($event)"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <!--    Task content-->
    <b-form-group :label="$t('TaskContent')" v-if="requiredFields.isContentRequired">
      <b-form-textarea
        v-model="task.content"
        rows="5"
      />
    </b-form-group>
    <!--    Google address -->
    <b-form-group :label="$t('Address')" v-if="requiredFields.isAddressRequired">
      <b-form-input v-model="task.address" />
    </b-form-group>

      <!-- Task for -->
      <sw-select :name="$t('task.TaskFor')">
          <assigned-users
                  class="mb-0"
                  :value="task.assignedUser"
                  :is-multiple="false"
                  :is-clearable="false"
                  @input="changeAssignedUsers"
          />
      </sw-select>
    <!--    Task time -->
    <b-form-group>
        <b-form-checkbox
                v-model="task.isWholeDay"
                variant="primary"
                class="custom-control-primary"
                @change="onChangeWholeDay"
        >
            {{ $t('WholeDay') }}
        </b-form-checkbox>
    </b-form-group>
    <b-row>
      <b-col :cols="isWholeDay ? 12 : 6">
          <sw-select :name="isWholeDay ? $t('TaskTerm') : $t('TaskStartTerm')">
            <sw-select-fpr
              :is-empty="task.startTime"
              @clear="task.startTime = ''"
            >
        <flat-pickr
          :key="`time_${isWholeDay}`"
          v-model="task.startTime"
          class="form-control"
          :config="{ ...options.timepicker, enableTime: !isWholeDay, locale: getCalendarLocale($i18n.locale), maxDate: task.endTime ? task.endTime : '', dateFormat: isWholeDay ? 'Y-m-d' : 'Y-m-d H:i' }"
        />
            </sw-select-fpr>
          </sw-select>
      </b-col>
      <b-col
        v-if="!isWholeDay"
        cols="6"
      >
          <sw-select :name="isWholeDay ? $t('TaskTerm') : $t('TaskEndTerm')">
            <sw-select-fpr
              :is-empty="task.endTime"
              @clear="task.endTime = ''"
            >
        <flat-pickr
          :key="`time_end_${isWholeDay}`"
          v-model="task.endTime"
          class="form-control"
          :config="{ ...options.timepicker, enableTime: !isWholeDay, locale: getCalendarLocale($i18n.locale), dateFormat: isWholeDay ? 'Y-m-d' : 'Y-m-d H:i', minDate: task.startTime ? task.startTime : '' }"
        />
            </sw-select-fpr>
          </sw-select>
      </b-col>
    </b-row>

      <div v-if="requiredFields.isReminderActive">
          <b-form-checkbox
                  v-model="task.sendReminderSms"
                  class="mt-50"
                  variant="primary"
          >{{ $t('ReminderSms') }}</b-form-checkbox>
          <!-- Edited sendSms -->
          <b-form-checkbox
                  v-model="task.sendReminderEmail"
                  class="mt-50"
                  variant="primary"
          >{{ $t('ReminderEmail') }}</b-form-checkbox>
      </div>

    <div v-if="requiredFields.isReminderActive && (task.sendReminderSms || task.sendReminderEmail)">
      <sw-select :name="$t('SendTaskReminder')">
        <v-select
          v-model="task.remindTimeBefore"
          :options="reminderTimes"
          :reduce="option => option.value"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
          <template #option="{ value, label }">
            {{ $t(`HourBefore`, { hour: label }) }}
          </template>
          <template #selected-option="{ value, label }">
            {{ $t(`HourBefore`, { hour: label }) }}
          </template>
        </v-select>
      </sw-select>
    </div>

    <!--  Upload files  -->
    <div
      v-if="task.files.length"
      class="mt-1"
    >
      <div class="mb-50">
        {{ $t('FilesAssignedToTask') }}
      </div>
      <documents-list
        :key="`task_${task.id}_${task.files.length}`"
        :delete-mode="true"
        :documents="task.files"
        @deleteFile="deleteFile"
      />
    </div>
    <DragDropUpload
      ref="dragdropupload"
      :is-refresh="false"
      @onChangeFiles="changeFiles"
      @onDeleteFiles="filesToUpload = []"
    />
    <!--    Footer-->
    <div class="d-flex justify-content-end pt-1 mt-1 border-top">
      <b-button
        size="sm"
        class="mr-50"
        variant="secondary"
        @click="onModalClose"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
        size="sm"
        variant="primary"
        :disabled="loading || !task.type || (requiredFields.isContentRequired && !task.content) || (requiredFields.isAddressRequired && !task.address) || !task.startTime || (isWholeDay ? false : !task.endTime)"
        @click="onCreateTask"
      >
        {{ okBtn }}
      </b-button>
    </div>
    <!--    Footer-->
  </div>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import {
  BButton, BFormCheckbox, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import {
  CLOSE_MODAL,
  CREATE_TASK, GET_CONTACT_TASKS,
  GET_TASKS,
  HIDE_MODAL, RELOAD_CONTENT,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { THREADS_SEARCH } from '@/@constants/fields'
import axiosIns from '@/libs/axios'
import moment from 'moment'
import EventBus from '@/event-bus'
import { eNewThreadTask } from '@/@types/events'
import DragDropUpload from '../../components/DragDropUpload.vue'

export default {
  name: 'TaskModal',
  components: {
    DocumentsList,
    AssignedUsers,
    DragDropUpload,
    vSelect,
    flatPickr,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
    BButton,
  },
  props: ['okBtn'],
  data: () => ({
    reminderTimes: [
      { label: '0:30h', value: 30 },
      { label: '1:00h', value: 60 },
      { label: '1:30h', value: 90 },
      { label: '2:00h', value: 120 },
      { label: '2:30h', value: 150 },
      { label: '3:00h', value: 180 },
      { label: '3:30h', value: 210 },
      { label: '4:00h', value: 240 },
      { label: '4:30h', value: 270 },
      { label: '5:00h', value: 300 },
      { label: '5:30h', value: 330 },
      { label: '6:00h', value: 360 },
      { label: '6:30h', value: 390 },
      { label: '7:00h', value: 420 },
      { label: '7:30h', value: 450 },
      { label: '8:00h', value: 480 },
      { label: '8:30h', value: 510 },
      { label: '9:00h', value: 540 },
      { label: '9:30h', value: 570 },
      { label: '10:00h', value: 600 },
      { label: '10:30h', value: 630 },
      { label: '11:00h', value: 660 },
      { label: '11:30h', value: 690 },
      { label: '12:00h', value: 720 },

    ],
    taskType: [],
    task: {
      type: '',
      content: '',
      address: '',
      startTime: '',
      endTime: '',
      assignedUser: [],
      isWholeDay: true,
      files: [],
      sendNotifyEmail: false,
      sendNotifySms: false,
      remindTimeBefore: 30,
      sendReminderSms: false,
      sendReminderEmail: false,
    },
    filesToUpload: [],
    unsignedTask: false,
    options: {
      timepicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
        locale: [pl, en],
      },
    },
    requiredFields: {
      isContentRequired: false,
      isAddressRequired: false,
      isReminderActive: false,
    },
    loading: false,

    contactThreadList: [],
    contactThread: null,
    contactThreadCopy: null,
  }),
  async mounted() {
    this.$store.dispatch(`typeSettings/${GET_TASKS}`)
    this.$store.dispatch('ranks/GET_RANKS_LIST')

    try {
      // eslint-disable-next-line
      const types = await this.$store.dispatch(`typeSettings/${GET_TASKS}`, { gte_id: '1001' })

      this.taskType = types
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
    }

    if (this.modalConfig.allowThreads?.length) {
      // eslint-disable-next-line prefer-destructuring
      this.contactThread = this.modalConfig.allowThreads[0]
      // eslint-disable-next-line prefer-destructuring
      this.contactThreadCopy = this.modalConfig.allowThreads[0]
    }

    if (!this.threadId && !this.modalConfig.threadId) {
      this.loadThreads('', loading => { this.isMeLoading = loading })
    } else {
      this.loadThread(this.threadId || this.modalConfig.threadId, loading => { this.isMeLoading = loading })
    }

    if (this.modalConfig.editedData) {
      const editedField = this.modalConfig.editedData
      const isWholeDay = (editedField.endAt && editedField.endAt.date.includes('23:59:59')) && (editedField.startAt && editedField.startAt.date.includes('00:00:00'))

      const content = editedField.content?.replaceAll('<br />', '\n') ?? ''
      this.task = {
        id: editedField.id,
        type: editedField.contactThreadTaskType,
        content,
        address: editedField.address,
        startTime: editedField.startAt.date.split('.')[0],
        endTime: !isWholeDay ? editedField.endAt.date.split('.')[0] : '',
        assignedUser: editedField.assignedUser ? [editedField.assignedUser] : [],
        files: editedField.files,
        isWholeDay,
        sendNotifyEmail: editedField.sendNotifyEmail ?? false,
        sendNotifySms: editedField.sendNotifySms ?? false,
        remindTimeBefore: editedField?.remindTimeBefore || 30,
        sendReminderSms: editedField?.sendReminderSms || false,
        sendReminderEmail: editedField?.sendReminderEmail || false,
      }

      const { isAddressRequired, isContentRequired, isReminderActive } = this.modalConfig.editedData.contactThreadTaskType
      this.requiredFields = { isAddressRequired, isContentRequired, isReminderActive }
      if (!editedField.contactThread) {
        this.unsignedTask = true
      }
    } else {
      this.task.assignedUser = [this.currentUser]
    }

    if (this.modalConfig.data?.event) {
      const { start, end, allDay } = this.modalConfig.data.event

      const startDate = moment(start)
      const endDate = moment(end)

      if (allDay) endDate.subtract(1, 'days')

      this.task.isWholeDay = startDate.isSame(endDate)
      this.task.startTime = startDate.format('YYYY-MM-DD HH:mm')
      this.task.endTime = endDate.format('YYYY-MM-DD HH:mm')
    }
  },
  computed: {
    ...mapGetters({
      ranks: 'ranks/getRanksList',
      modalConfig: 'modal/getModalState',
      currentUser: 'auth/getCurrentUser',
      system: 'system/getSettings',
    }),
    isWholeDay() {
      return this.task.isWholeDay
    },
    locale() {
      return this.$i18n.locale
    },
  },
  methods: {
    async modalClose() { await this.$store.commit(`modal/${CLOSE_MODAL}`) },
    async modalReloadContent(payload) { await this.$store.commit(`modal/${RELOAD_CONTENT}`, payload) },
    deleteFile(fileIndex) {
      this.task.files.splice(fileIndex, 1)
    },
    changeFiles(files) {
      this.filesToUpload.push(...files)
    },
    changeAssignedUsers(payload) {
      this.task.assignedUser = [payload]
    },
    checkRequireds({ isAddressRequired, isContentRequired, isReminderActive }) {
      this.requiredFields = { isAddressRequired, isContentRequired, isReminderActive }
    },
    onChangeWholeDay() {
      this.task.startTime = ''
      this.task.endTime = ''
    },
    onModalClose() {
      this.$emit('close')
    },
    onCreateTask() {
      this.loading = true
      const { task } = this
      const threadId = this.contactThread?.id || null
      const content = task.content.replaceAll('\n', '<br />')
      const taskData = {
        name: 'Nazwa ...',
        contactThread: threadId,
        contactThreadTaskType: typeof task.type === 'object' ? task.type.id.toString() : task.type.toString(),
        content,
        startAt: this.isWholeDay && !task.startTime.includes('00:00:00') ? `${task.startTime} 00:00:00` : task.startTime,
        endAt: this.isWholeDay ? `${task.startTime} 23:59:59`.replaceAll('00:00:00', '') : task.endTime,
        address: task.address,
        sendNotifyEmail: task.sendNotifyEmail,
        sendNotifySms: task.sendNotifySms,
        remindTimeBefore: task.sendReminderSms || task.sendReminderEmail ? (task.remindTimeBefore || null) : null,
        sendReminderSms: task.sendReminderSms || false,
        sendReminderEmail: task.sendReminderEmail || false,
        // contactThread: this.getObjectId(contactThread),
      }

      if (Array.isArray(task.assignedUser) && task.assignedUser.length) {
        taskData.assignedUser = task.assignedUser[0].id.toString()
      } else if (typeof task.assignedUser === 'string') {
        taskData.assignedUser = task.assignedUser
      } else {
        taskData.assignedUser = null
      }

      // if (task.assignedUser) {
      //   console.log(task.assignedUser)
      //   taskData.assignedUser = typeof task.assignedUser === 'object' ? task.assignedUser[0].id.toString() : task.assignedUser.toString()
      // }

      if (typeof task.type === 'object') {
        if (!task.type.isAddressRequired) delete taskData.address
        if (!task.type.isContentRequired) delete taskData.content
      }

      if (task.id) taskData.id = task.id.toString()
      /* eslint-disable */
      this.$store.commit(`modal/${HIDE_MODAL}`)
      this.$store.dispatch(`uploader/${UPLOAD_FILES}`, { uploadedFiles: this.filesToUpload, type: 'task', threadId })
        .then(res => {
          taskData.files = [...res.files, ...task.files.map(file => file.token)]
          this.$store.dispatch(`modal/${CREATE_TASK}`, { taskData })
            .then(threadId => {
              this.loading = false
              this.showToast('success', taskData.id ? this.$i18n.t('TaskUpdated') : this.$i18n.t('TaskCreated'))

              if (threadId) {
                this.$store.dispatch(`singleContact/${GET_CONTACT_TASKS}`, { threadId })
                    .catch(err => {
                      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
                    })
              }
              console.log(1233)
              this.$nextTick(() => this.modalReloadContent({ id: threadId }).then(() => this.modalClose()))

              if (this.contactThread?.id) EventBus.$emit(eNewThreadTask, { threadId: this.contactThread.id })
            })
            .catch(err => {
              this.loading = false
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })

      // this.$store.dispatch(`modal/${CREATE_TASK}`, taskData)
      //   .then(threadId => {
      //     this.showToast('success', taskData.id ? this.$i18n.t('TaskUpdated') : this.$i18n.t('TaskCreated'))
      //     this.$store.dispatch(`singleContact/${GET_CONTACT_TASKS}`, { threadId })
      //       .then(() => {})
      //       .catch(err => {
      //         this.showToast('danger', this.$i18n.t(`errors.${err}`))
      //       })
      //   })
      //   .catch(() => {
      //     this.showToast('danger', this.$i18n.t('ProblemOccured'))
      //   })
    },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id) {
      const thread = await axiosIns(`1/contacts/threads/${id}`, { params: { fields_load: THREADS_SEARCH } })
      if (thread.data.data.item) {
        this.contactThread = thread.data.data.item
        this.contactThreadCopy = thread.data.data.item
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
