import {
  GET_CONTACT_FUNNEL_POINTS,
  GET_CONTACT,
  GET_CONTACT_INFO,
  GET_THREAD,
  GET_THREAD_LIST_ITEM,
  GET_THREAD_LIST,
  LOAD_CURRENT_CONTACT,
  GET_CONTACT_FUNNELS,
  GET_CURRENT_CONTACT_FUNNELS,
  GET_CONTACT_ADDITIONAL_FIELDS,
  REMOVE_CONTACT,
  REMOVE_CURRENT_CONTACT,
  GET_CURRENT_CONTACT,
  GET_CURRENT_CONTACT_THREAD_LIST,
  SWITCH_PRIMARY_THREAD,
  REMOVE_THREAD,
  REMOVE_CURRENT_CONTACT_THREAD,
  GET_CURRENT_CONTACT_FUNNEL_POINTS,
  UPDATE_CONTACT_THREAD_FUNNEL_PROCESS,
  UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE,
  ADD_CONTACT_THREAD_FUNNEL_PROCESS,
  ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  GET_CURRENT_CONTACT_ADDITIONAL_FIELDS,
  GET_CURRENT_CONTACT_IDENTITY_DOCUMENTS,
  GET_CONTACT_DELIVERY_ADDRESSES,
  GET_CONTACT_IDENTITY_DOCUMENTS,
  GET_CURRENT_CONTACT_DELIVERY_ADDRESSES,
  REMOVE_CONTACT_ADDITIONAL_FIELD,
  REMOVE_CURRENT_CONTACT_ADDITIONAL_FIELD,
  GET_THREAD_TASKS,
  GET_THREAD_TASK,
  GET_CURRENT_THREAD_TASKS,
  GET_CONTACT_STATUSES,
  CHANGE_CONTACT_STATUS,
  CHANGE_CURRENT_CONTACT_STATUS,
  GET_CONTACT_THREAD_CLOSE_STATUS_REASONS,
  CLOSE_CONTACT_THREAD_FUNNEL_PROCESS,
  CLOSE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  GET_FULL_CURRENT_CONTACT_THREAD_LIST,
  GET_CONTACT_ASSIGNED_THREADS,
  GET_CURRENT_CONTACT_ASSIGNED_THREADS,
  GET_CONTACT_THREAD_FUNNEL_PROCESS, GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS, GET_CURRENT_THREAD_TASK,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  CONTACT_ADDITIONAL_FIELDS,
  CONTACT_THREAD_LIST,
  CONTACT_THREAD,
  SETTINGS_FUNNEL_TYPE,
  CONTACT_INFO,
  CONTACT,
  CONTACT_DELIVERY_ADDRESSES,
  CONTACT_IDENTITY_DOCUMENTS,
  SETTINGS_CONTACTS_STATUSES, TASKS, CONTACT_THREAD_CLOSE_REASONS, CONTACT_THREAD_FUNNEL_PROCESS,
} from '@/@constants/fields'
import { showAlert } from '@core/mixins/ui/alert'
import i18n from '@/libs/i18n'
// import { showToast } from '@core/mixins/ui/toast'

// It does what does
const getObjectId = item => {
  if (typeof item === 'string') return item
  if (Array.isArray(item)) return item.map(getObjectId)
  if (item?.id) return item.id

  return item || null
}

export default {
  namespaced: true,
  state: {
    threadLoading: true,
    currentContact: null,
    currentThread: null,
    funnels: [],
    funnelPoints: [],
  },
  getters: {
    contact(state) { return state.currentContact },
    organization(state) { return state.currentContact.organization },
    thread(state) { return state.currentThread },
    threadLoading(state) { return state.threadLoading },
    funnels(state) { return state.funnels },
    funnelPoints(state) { return state.funnelPoints },
    funnelProgress(state) { return state.currentThread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.progressPercent },
    checklistProgress(state) {
      const details = {
        done: 0,
        all: 0,
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const detail of state.currentThread.contactThreadChecklistDetails) {
        details.done += detail.contactThreadChecklistTypePointsCount
        details.all += detail.contactThreadChecklistPointDataProgress
      }

      return details
    },
    contactName(state) {
      if (!state.currentContact) return ''

      const { firstName, lastName } = state.currentContact

      return `${firstName} ${lastName}`
    },
  },
  mutations: {
    async changeLoading(state, value) {
      state.threadLoading = value
    },
    async changeActiveThread(state, thread) {
      try {
        if (thread.fromAssignedContact) {
          await showAlert('warning', `${i18n.t('Warning')}!`, i18n.t('ThisContactIsAssignedWithOtherThread'))

          await router.push(`/new_contact/${thread.contact.id}/thread/${thread.id}/timeline`)
          return
        }

        state.currentThread = await this.dispatch(`contact/${GET_THREAD}`, thread.id)

        await router.push(`/new_contact/${state.currentContact.id}/thread/${state.currentThread.id}/timeline`)
      } catch (err) {
        console.log(err)
        // showToast('danger', i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    updateCurrentThread(state, thread) {
      if (state.currentThread.id === thread.id) Object.assign(state.currentThread, thread)
    },
    changeCurrentThreadPrimary(state, isPrimary) {
      state.currentThread.isPrimary = isPrimary || !state.currentThread.isPrimary
    },
  },
  actions: {
    /**  Load full contact (contact + thread)  */
    [LOAD_CURRENT_CONTACT]: ({ state, dispatch }, data = router.currentRoute.params) => new Promise((resolve, reject) => {
      const { contactId, threadId } = data

      if (threadId) {
        Promise.all([
          dispatch(GET_CONTACT_INFO, contactId),
          dispatch(GET_THREAD, threadId),
        ])
          .then(([contact, thread]) => {
            state.currentContact = contact
            state.currentThread = thread

            resolve(state)
          })
          .catch(err => reject(err))
      } else {
        dispatch(GET_CONTACT_INFO, contactId)
          .then(contact => { state.currentContact = contact })
          .catch(err => reject(err))
      }
    }),
    /**  Return contact  */
    [GET_CONTACT]: (_, id) => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT }

      axiosIns.get(`1/contacts/${id}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Contact was not found!')

          resolve(item)
        })
        .catch(err => reject(err))
    }),
    /**  Return current contact  */
    [GET_CURRENT_CONTACT]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT, state.currentContact?.id)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Return contact info  */
    [GET_CONTACT_INFO]: (_, id) => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT_INFO }

      axiosIns.get(`1/contacts/${id}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Contact was not found!')

          resolve(item)
        })
        .catch(err => reject(err))
    }),
    /**  Return thread tasks  */
    [GET_THREAD_TASKS]: (_, id) => new Promise((resolve, reject) => {
      const params = {}

      params.orderBy = 'startAt.asc'
      params.fields_load = TASKS

      axiosIns.get(`1/contacts/threads/${id}/tasks`, { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),
    /**  Return thread task  */
    [GET_THREAD_TASK]: (_, { threadId, taskId }) => new Promise((resolve, reject) => {
      const params = {}

      params.fields_load = TASKS

      axiosIns.get(`1/contacts/threads/${threadId}/tasks/${taskId}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Task was not found!')

          resolve(item)
        })
        .catch(err => reject(err))
    }),
    /**  Return current thread task  */
    [GET_CURRENT_THREAD_TASK]: ({ state, dispatch }, id) => new Promise((resolve, reject) => {
      dispatch(GET_THREAD_TASK, { threadId: state.currentThread?.id, taskId: id })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Return current contact tasks  */
    [GET_CURRENT_THREAD_TASKS]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_THREAD_TASKS, state.currentContact?.id)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Return contact identity documents  */
    [GET_CONTACT_IDENTITY_DOCUMENTS]: (_, id) => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT_IDENTITY_DOCUMENTS }

      axiosIns.get(`1/contacts/${id}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Contact was not found!')

          resolve(item.personIdentityDocuments)
        })
        .catch(err => reject(err))
    }),
    /**  Return current contact identity documents  */
    [GET_CURRENT_CONTACT_IDENTITY_DOCUMENTS]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT_IDENTITY_DOCUMENTS, state.currentContact?.id)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Return contact delivery addresses  */
    [GET_CONTACT_DELIVERY_ADDRESSES]: (_, id) => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT_DELIVERY_ADDRESSES }

      axiosIns.get(`1/contacts/${id}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Contact was not found!')

          resolve(item.deliveryAddresses)
        })
        .catch(err => reject(err))
    }),
    /**  Return current contact delivery addresses  */
    [GET_CURRENT_CONTACT_DELIVERY_ADDRESSES]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT_DELIVERY_ADDRESSES, state.currentContact?.id)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Return thread  */
    [GET_THREAD]: (_, id) => new Promise((resolve, reject) => {
      _.commit('changeLoading', true)

      const params = { fields_load: CONTACT_THREAD }

      axiosIns.get(`1/contacts/threads/${id}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Process was not found!')

          resolve(item)
        })
        .catch(err => reject(err))
        .finally(() => {
          setTimeout(() => {
            _.commit('changeLoading', false)
          }, 1000)
        })
    }),
    /**  Return thread list item  */
    [GET_THREAD_LIST_ITEM]: (_, id) => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT_THREAD_LIST }

      axiosIns.get(`1/contacts/threads/${id}`, { params })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Process was not found!')

          resolve(item)
        })
        .catch(err => reject(err))
    }),
    /**  Return thread list  */
    [GET_THREAD_LIST]: (_, id) => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT_THREAD_LIST }

      axiosIns.get(`1/contacts/${id}/threads`, { params })
        .then(res => res.data.data.items)
        .then(items => resolve(items))
        .catch(err => reject(err))
    }),
    /**  Return current contact thread list  */
    [GET_CURRENT_CONTACT_THREAD_LIST]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_THREAD_LIST, state.currentContact?.id)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Return full current contact thread list with assigned threads  */
    [GET_FULL_CURRENT_CONTACT_THREAD_LIST]: ({ dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CURRENT_CONTACT_THREAD_LIST)
        .then(res => {
          dispatch(GET_CURRENT_CONTACT)
            .then(contact => {
              if (!contact.contactThreadAssignedContacts.length) resolve(res)

              const threadsFromContact = contact.contactThreadAssignedContacts.filter(({ contactThread }) => res.find(item => item.id !== contactThread.id) || !res.length).map(
                ({ contactThread }) => ({ ...contactThread, fromAssignedContact: true }),
              )

              resolve([...res, ...threadsFromContact])
            })
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    }),
    /**  Return contact assigned threads  */
    [GET_CONTACT_ASSIGNED_THREADS]: ({ dispatch }, { id, threads = [] }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT, id)
        .then(res => {
          const threadsFromContact = res.contactThreadAssignedContacts.filter(({ contactThread }) => threads.find(item => item.id !== contactThread.id) || !threads.length).map(
            ({ contactThread }) => ({ ...contactThread, fromAssignedContact: true }),
          )

          resolve(threadsFromContact)
        })
        .catch(err => reject(err))
    }),
    /**  Return current contact assigned threads  */
    [GET_CURRENT_CONTACT_ASSIGNED_THREADS]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CURRENT_CONTACT_THREAD_LIST)
        .then(res => dispatch(GET_CONTACT_ASSIGNED_THREADS, { id: state.currentContact?.id, threads: res })
          .then(threadsFromContact => resolve(threadsFromContact))
          .catch(err => reject(err)))
        .catch(err => reject(err))
    }),
    /**  Get funnel points  */
    [GET_CONTACT_FUNNEL_POINTS]: (_, thread) => new Promise((resolve, reject) => {
      if (!thread) reject(new Error('Thread is required'))

      if (!thread.contactThreadFunnelProcessSelected?.contactThreadFunnelTypePoint) reject(new Error('No funnel process'))

      const funnelId = thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.contactThreadFunnelType.id

      const params = {
        fields_load: SETTINGS_FUNNEL_TYPE,
      }

      axiosIns.get(`1/settings/contactThreadFunnelTypes/${funnelId}`, { params })
        .then(res => {
          const data = res.data.data?.item?.contactThreadFunnelTypePoints

          const i = data.findIndex(({ id }) => id === thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint.id)

          data[i].active = true

          resolve((data || []).sort((a, b) => a.position - b.position))
        })
        .catch(err => {
          reject(err)
        })
    }),
    /**  Get current funnel points  */
    [GET_CURRENT_CONTACT_FUNNEL_POINTS]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT_FUNNEL_POINTS, state.currentThread)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    // Contact statuses
    [GET_CONTACT_STATUSES]: () => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactStatuses', { params: { fields_load: SETTINGS_CONTACTS_STATUSES, orderBy: 'position.asc' } })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),
    /**  Get funnels  */
    [GET_CONTACT_FUNNELS]: (_, thread) => new Promise((resolve, reject) => {
      if (!thread) reject(new Error('Thread is required'))

      axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
        .then(res => res.data.data.items.filter(item => item.contactThreadFunnelTypePoints.length))
        .then(funnels => {
          const combinedFunnels = funnels

          // eslint-disable-next-line no-restricted-syntax
          for (const funnel of funnels) {
            const f = thread.contactThreadFunnelProcesses.find(({ contactThreadFunnelType }) => contactThreadFunnelType.id === funnel.id)

            if (f) {
              funnel.status = f.status

              switch (funnel.status) {
                case 'CLOSED_POSITIVE':
                  funnel.variant = 'success'
                  funnel.title = 'COMPLETED'
                  break
                case 'CLOSED_NEGATIVE':
                  funnel.variant = 'danger'
                  funnel.title = 'REJECTED'
                  break
                case 'PAUSED':
                  funnel.variant = 'warning'
                  funnel.title = 'PAUSED'
                  break
                default:
                  funnel.variant = 'primary'
                  break
              }
            }
          }

          resolve(combinedFunnels)
        })
        .catch(err => {
          reject(err)
        })
    }),
    /**  Get current funnels  */
    [GET_CURRENT_CONTACT_FUNNELS]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT_FUNNELS, state.currentThread)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Get contact additional fields  */
    [GET_CONTACT_ADDITIONAL_FIELDS]: (_, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: CONTACT_ADDITIONAL_FIELDS,
      }

      axiosIns.get(`1/contact/${id}/addonFields`, { params })
        .then(res => resolve(res.data.data.items.sort(((a, b) => a.contactAddonFieldType?.position - b.contactAddonFieldType?.position))))
        .catch(err => reject(err))
    }),
    /**  Get current contact additional fields  */
    [GET_CURRENT_CONTACT_ADDITIONAL_FIELDS]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT_ADDITIONAL_FIELDS, state.currentContact?.id)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Remove contact additional field  */
    [REMOVE_CONTACT_ADDITIONAL_FIELD]: (_, { id, fieldId }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/contact/${id}/addonFields`, { data: [fieldId] })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }),
    /**  Remove current contact additional field  */
    [REMOVE_CURRENT_CONTACT_ADDITIONAL_FIELD]: ({ state, dispatch }, id) => new Promise((resolve, reject) => {
      dispatch(REMOVE_CONTACT_ADDITIONAL_FIELD, { id: state.currentContact?.id, fieldId: id })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Change contact status  */
    [CHANGE_CONTACT_STATUS]: (_, { id, statusId }) => new Promise((resolve, reject) => {
      if (!id || !statusId) {
        reject(new Error('Contact and status is required'))
        return
      }

      const payload = {
        id,
        status: statusId,
      }

      axiosIns.patch('1/contacts', payload)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /**  Change current contact status  */
    [CHANGE_CURRENT_CONTACT_STATUS]: ({ state, dispatch }, status) => new Promise((resolve, reject) => {
      dispatch(CHANGE_CONTACT_STATUS, { id: state.currentContact?.id, statusId: status.id })
        .then(res => {
          state.currentContact.status = status

          return resolve(res)
        })
        .catch(err => reject(err))
    }),
    /** Add contact funnel process */
    [ADD_CONTACT_THREAD_FUNNEL_PROCESS]: (_, { id, payload }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/contacts/threads/${id}/funnelProcesses`, payload)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /** Add current contact funnel process */
    [ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS]: ({ state, dispatch }, payload) => new Promise((resolve, reject) => {
      dispatch(ADD_CONTACT_THREAD_FUNNEL_PROCESS, { id: state.currentThread?.id, payload })
        .then(({ data }) => data.data)
        .then(({ items }) => resolve(items[0].id))
        .catch(err => reject(err))
    }),
    /** Update contact funnel process */
    [UPDATE_CONTACT_THREAD_FUNNEL_PROCESS]: (_, { id, payload }) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/contacts/threads/${id}/funnelProcesses`, payload)
        .then(({ data }) => data.data)
        .then(({ items }) => resolve(items[0].id))
        .catch(err => reject(err))
    }),
    /** Update current contact funnel process */
    [UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS]: ({ state, dispatch }, payload) => new Promise((resolve, reject) => {
      dispatch(UPDATE_CONTACT_THREAD_FUNNEL_PROCESS, { id: state.currentThread?.id, payload })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /** Get contact funnel process */
    [GET_CONTACT_THREAD_FUNNEL_PROCESS]: (_, { id, funnelProcessId }) => new Promise((resolve, reject) => {
      axiosIns.get(`1/contacts/threads/${id}/funnelProcesses/${funnelProcessId}`, { params: { fields_load: CONTACT_THREAD_FUNNEL_PROCESS } })
        .then(({ data }) => data)
        .then(({ data }) => data)
        .then(({ item }) => {
          if (!item) throw new Error('Thread process was not found!')

          resolve(item)
        })
        .catch(err => reject(err))
    }),
    /** Get contact funnel process */
    [GET_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS]: ({ state, dispatch }, id) => new Promise((resolve, reject) => {
      dispatch(GET_CONTACT_THREAD_FUNNEL_PROCESS, { id: state.currentThread.id, funnelProcessId: id })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /** Change current contact funnel process active */
    [CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE]: ({ dispatch }, { id, funnelPointId }) => new Promise((resolve, reject) => {
      const payload = {
        id,
        contactThreadFunnelTypePoint: funnelPointId,
      }

      dispatch(UPDATE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS, payload)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /** Close contact funnel process */
    [CLOSE_CONTACT_THREAD_FUNNEL_PROCESS]: ({ dispatch }, {
      thread, closeNote, create = false, open = false,
    }) => new Promise((resolve, reject) => {
      const status = closeNote.status !== 'REJECTED' ? 'CLOSED_POSITIVE' : 'CLOSED_NEGATIVE'
      const openedId = thread?.contactThreadFunnelProcessSelected.id

      let payload = {
        status,
        statusNote: closeNote.note,
        contactThreadStatusReason: getObjectId(closeNote.reason),
        id: openedId,
      }

      if (create) {
        this.showOpenProcess = true
        return
      }

      if (open) {
        payload = {
          status: 'OPEN',
          statusNote: null,
          contactThreadStatusReason: null,
          id: openedId,
        }
      }

      dispatch(UPDATE_CONTACT_THREAD_FUNNEL_PROCESS, { id: thread.id, payload })
        .then(res => resolve({ res, payload }))
        .catch(err => reject(err))
    }),
    /** Close current contact funnel process */
    [CLOSE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS]: ({ state, dispatch }, {
      closeNote, reason, create = false, open = false,
    }) => new Promise((resolve, reject) => {
      dispatch(CLOSE_CONTACT_THREAD_FUNNEL_PROCESS, {
        thread: state.currentThread, closeNote, create, open,
      })
        .then(data => {
          state.currentThread.status = data.payload.status
          state.currentThread.statusNote = data.payload.statusNote
          state.currentThread.contactThreadStatusReason = open ? null : reason

          resolve(data.res)
        })
        .catch(err => reject(err))
    }),
    /** Switch thread primary */
    [SWITCH_PRIMARY_THREAD]: (_, { id, thread }) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/contacts/${id}/threads`, { isPrimary: !thread.isPrimary, id: thread.id })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /** Remove contact */
    [REMOVE_CONTACT]: ({ state }, contact) => new Promise((resolve, reject) => {
      showAlert('warning', i18n.t('DeleteDefault', { name: `${contact.firstName} ${contact.lastName}` }), i18n.t('ContactDeleteAlert'))
        .then(() => {
          axiosIns.delete('1/contacts', { data: [contact.id] })
            .then(res => {
              router.push({ name: 'contacts' }).then(() => {
                resolve(res)

                state.currentContact = null
                state.currentThread = null
              })
            })
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    }),
    /** Get thread close status reasons */
    [GET_CONTACT_THREAD_CLOSE_STATUS_REASONS]: () => new Promise((resolve, reject) => {
      const params = { fields_load: CONTACT_THREAD_CLOSE_REASONS, orderBy: 'position.asc' }

      axiosIns.get('1/settings/contactThreadStatusReasons', { params })
        .then(res => resolve(res.data.data.items))
        .catch(err => reject(err))
    }),
    /** Remove current contact */
    [REMOVE_CURRENT_CONTACT]: ({ state, dispatch }) => new Promise((resolve, reject) => {
      dispatch(REMOVE_CONTACT, state.currentContact)
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
    /** Remove thread */
    [REMOVE_THREAD]: ({ state }, { id, thread }) => new Promise((resolve, reject) => {
      if (state.currentThread?.id === thread.id) {
        reject(new Error('The active thread cannot be deleted!'))
        return
      }

      showAlert('warning', i18n.t('DeleteDefault', { name: thread.name }), i18n.t('ThreadDeleteAlert'))
        .then(() => {
          axiosIns.delete(`1/contacts/${id}/threads`, { data: [thread.id] })
            .then(res => resolve(res))
            .catch(err => reject(err))
        })
        .catch(err => reject(err))
    }),
    /** Remove current thread */
    [REMOVE_CURRENT_CONTACT_THREAD]: ({ state, dispatch }, id) => new Promise((resolve, reject) => {
      dispatch(REMOVE_THREAD, { id: state.currentContact?.id, thread: id })
        .then(res => resolve(res))
        .catch(err => reject(err))
    }),
  },
}
