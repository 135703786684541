import store from '@/store'

export default function useSwUtils() {
  const isTimeLimitReached = (validationDate, limit = null, limitBySettingsField = 'contactThreadTaskDeleteTime') => {
    if (!limit && limitBySettingsField) {
      // eslint-disable-next-line
      limit = store.getters['system/getSettings']?.contactThreadTaskDeleteTime || 15
    }

    const serverDate = new Date(store.getters['system/getSettings'].currentDatetime.date)
    const currentDate = new Date()

    const date = new Date(validationDate)
    const taskEnd = new Date(date.getTime() + limit * 60000)

    return ((currentDate - serverDate) > (currentDate - taskEnd))
  }

  const statusColor = (status, colorPrefix = '', colorSuffix = '') => {
    const danger = ['NOT_FOUND', 'EXPIRED', 'UNDELIVERED', 'FAILED', 'REJECTED', 'STOP', 'UNKNOWN', 'CANCELLED', 'CANCELED']
    const warnings = ['QUEUE', 'RENEWAL', 'SIGNING']
    const success = ['DELIVERED', 'DONE', 'SIGNED', 'ACCEPTED', 'SIGNED_MANUALLY', 'SIGNED_ELECTRONICALLY']
    const info = ['SENT']
    const primary = ['NEW']

    let color = 'primary'
    if (danger.includes(status)) color = 'danger'
    if (warnings.includes(status)) color = 'warning'
    if (success.includes(status)) color = 'success'
    if (info.includes(status)) color = 'info'
    if (primary.includes(status)) color = 'primary'

    return [colorPrefix, color, colorSuffix].filter(Boolean).join('-')
  }

  return {
    isTimeLimitReached,
    statusColor,
  }
}
