<template>
  <div>
    <!--      Thread      -->
    <sw-select
      :name="$t('SelectContacts')"
      class="mb-1"
    >
      <v-select
        v-model="contactThread"
        :options="modalConfig.allowThreads && modalConfig.allowThreads.length ? modalConfig.allowThreads :contactThreadList"
        label="name"
        :filterable="false"
        :placeholder="$t('SelectContacts')"
        @search="(e, is) => {
          if (modalConfig.allowThreads && modalConfig.allowThreads.length) return null
          else loadThreads(e, is)
        }"
      >
        <template #no-options="{ search }">
          <span v-if="search.length">
            {{ $t('NoData') }}
          </span>
          <span v-else>
            {{ $t('TypeToSearch') }}
          </span>
        </template>
        <template #option="{ name, contact, offersTotal }">
          <div class="d-flex align-items-center">
            <avatar
              :user="contact"
              class="mr-25"
            />  - {{ name }}
            <feather-icon
              v-if="offersTotal >= system.contactThreadOffersLimit"
              v-b-tooltip
              icon="AlertCircleIcon"
              class="ml-50"
              :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
            />
          </div>
        </template>

        <template #selected-option="{ name, contact }">
          <div class="d-flex">
            <avatar
              :user="contact"
              class="mr-25"
            /> – {{ name }}
          </div>
        </template>
      </v-select>
    </sw-select>

    <!-- Sms sender -->
    <sw-select :name="$t('SmsSender')"
               class="mb-1"
             v-if="smsSenders.length"
    >
      <v-select
        v-model="sms.smsSender"
        :options="smsSenders || []"
        label="name"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <!--      Send To      -->
    <sw-select
      :name="$t('SendTo')"
      class="mb-1"
    >
      <v-select
        v-model="sms.sentTo"
        :options="userOptions || []"
        label="firstName"
        :placeholder="$t('SendTo')"
        :selectable="user => !!user.phone"
        :get-option-key="option => option.type + ' ' + option.id"
        multiple
      >
        <template slot="no-options">
          {{ $t('NoOptions') }}
        </template>
        <template #option="{ avatar, firstName, lastName, type, phone }">
          <b-avatar
            size="sm"
            :src="avatar"
            :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
          />
          <span class="ml-50 pt-25">{{ firstName }} {{ lastName }}
            <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
            {{ phone ? `- ${phone}` : '' }}
          </span>
        </template>

        <template #selected-option="{ avatar, firstName, lastName, type }">
          <b-avatar
            size="sm"
            class="border border-white"
            :src="avatar"
            :text="firstName.slice(0,1).toUpperCase() + lastName.slice(0,1).toUpperCase()"
          />
          <span class="ml-50 pt-25">
            {{ firstName }} {{ lastName }}
            <span v-if="type === 'c'">- {{ $t('Contact') }}</span>
          </span>
        </template>
      </v-select>
    </sw-select>

    <!--    Sms template -->
    <sw-select
      :name="$t('SmsTemplate')"
      class="mb-1"
    >
      <v-select
        v-model="sms.template"
        label="name"
        :placeholder="$t('SmsTemplate')"
        :options="options.smsTemplates"
        :reduce="template => template.content"
        @input="changeTemplate"
        @open="loadTemplates"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
      </v-select>
    </sw-select>

    <sw-select :name="$t('PlanSending')">
      <sw-select-fpr
        :is-empty="sms.scheduledAt"
        @clear="sms.scheduledAt = ''"
      >
        <flat-pickr
          v-model="sms.scheduledAt"
          class="form-control form-control-sm"
          :config="{ enableTime: true, locale: getCalendarLocale($i18n.locale), minDate: new Date() }"
        />
      </sw-select-fpr>
    </sw-select>

    <!-- Field: AI ON/OFF -->
    <div
      v-if="checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE]) && smsConfig && smsConfig.enabled"
      class="d-flex flex-row flex-wrap justify-content-start align-items-center mb-1"
    >
      <label
        for="isOn-ai"
        class="form-label mb-0"
      >
        {{ $t('UseGeneratedAIMessage') }}:
      </label>

      <b-form-checkbox
        id="isOn-ai"
        v-model="useGeneratedAIMessage"
        class="form-label ml-50"
        variant="primary"
        switch
      />

      <p
        v-if="!useGeneratedAIMessage"
        class="text-primary mb-0"
        style="opacity: .8"
      >
        <feather-icon icon="ArrowLeftIcon" />

        {{ $t('EnableAnswerGenerator') }}
      </p>
    </div>

    <!-- Help Prompts -->
    <div
      v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE])"
      class="mb-1"
    >
      <label>
        {{ `${$t('HelpPrompts')}` }}
      </label>

      <div class="position-relative prompt-carousel">
        <div
          class="d-flex overflow-x-scroll px-50"
          style="row-gap: .5rem; column-gap: .5rem"
        >
          <b-button
            v-for="(name, index) in Object.keys(AI_PROMPTS_SMS)"
            :key="index"
            variant="outline-primary"
            size="sm"
            class="d-flex align-items-center"
            style="column-gap: .34rem"
            @click="sms.content = AI_PROMPTS_SMS[name]"
          >
            <feather-icon icon="StarIcon" />

            <p class="mb-0 text-nowrap">
              {{ $t(`ai.helpPrompts.${name}`) }}
            </p>
          </b-button>
        </div>
      </div>
    </div>

    <!--   Sms content -->
    <div class="position-relative w-100">
      <div>
        <label for="sms-content">
          <template v-if="!useGeneratedAIMessage">
            {{ `${$t('MessageContent')}` }}
          </template>

          <template v-else>
            <span class="text-primary">{{ $t('EnterQueryForAIBgenerator') }}</span>
          </template>

          <!--          <template v-if="useGeneratedAIMessage">-->
          <!--            / <span class="text-primary">{{ $t('AIGeneration') }}</span>-->
          <!--          </template>-->
        </label>

        <!-- Field: Message - Quill Editor -->
        <div class="message-editor">
          <div class="position-relative">
            <b-form-textarea
              id="sms-content"
              v-model="sms.content"
              :state="sms.content.length <= 500 ? (sms.content.length > 0 ? true : null) : false"
              class="char-textarea"
              :aria-invalid="false"
              :placeholder="$t('MessageContent')"
              rows="5"
            />
            <small
              class="textarea-counter-value float-right"
              :class="sms.content.length >= 500 ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ sms.content.length }}</span> / 500
            </small>

            <b-button
              v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE])"
              size="sm"
              variant="primary"
              :disabled="sms.content.length > 20000 || sms.content.length === 0 || !useGeneratedAIMessage"
              style="width: max-content"
              class="mt-50 mb-50"
              @click="aiGeneration"
            >
              {{ $t('GenerateMessage') }} AI
            </b-button>
          </div>

          <div
            v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE])"
            class="w-100 mt-50 position-relative"
          >
            <label
              for="sms-content"
              class="mb-50"
            >
              {{ $t('AIResponseGenerated') }}
            </label>

            <div
              class="border rounded-lg px-2 py-1 position-relative"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="aiResponseTyped.length"
                v-html="aiResponseTyped"
              />

              <p
                v-else
                class="text-primary text-center h5 mb-0"
              >
                {{ $t('NoAnswer') }}
              </p>

              <small
                class="float-right"
              >
                <span class="char-count" />
              </small>

              <b-badge
                variant="primary"
                class="position-absolute mt-1 position-right-0 mr-75"
                style="border-top-left-radius: 0; border-top-right-radius: 0; font-size: .6rem;"
              >
                {{ $t('UsedUp') }}:
                {{ totalTokens }}
              </b-badge>
            </div>

            <b-overlay
              :show="!useGeneratedAIMessage"
              no-wrap
            >
              <template #overlay>
                <span class="text-primary">
                  {{ $t('EnableAnswerGenerator') }}
                </span>
              </template>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>

    <!--    Sms share-->
    <sw-select>
      <v-select
        v-model="sms.share"
        class="mt-2"
        multiple
        :placeholder="$t('ShareMessage')"
        :close-on-select="false"
        :reduce="rank => rank.id.toString()"
        label="name"
        :options="ranks"
      >
        <template #no-options>
          {{ $t('NoOptions') }}
        </template>
        <template #option="{ name }">
          <span class="ml-50"> {{ name }}</span>
        </template>

        <template #selected-option="{ name }">
          <span class="ml-50"> {{ name }}</span>
        </template>
      </v-select>
    </sw-select>

    <!--    Footer-->
    <div
      class="d-flex justify-content-end pt-1 mt-1 border-top"
      :class="{ 'justify-content-between': useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE]) && checkRequiredPermissions([$roles.CHAT_GPT_REPORT_ADD]) }"
    >
      <b-button
        v-if="useGeneratedAIMessage && checkRequiredModule('chatGptModule') && checkRequiredPermissions([$roles.CHAT_GPT_USE]) && checkRequiredPermissions([$roles.CHAT_GPT_REPORT_ADD])"
        variant="danger"
        size="sm"
        class="mr-50"
        :disabled="isAiLoading || !useGeneratedAIMessage || !aiResponseTyped.length"
        @click="openReportModal"
      >
        {{ $t('ReportAIMessage') }}
      </b-button>

      <div>
        <b-button
          variant="secondary"
          size="sm"
          class="mr-50"
          @click="onModalClose"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="!(sms.sentTo.length > 0) || sms.content.length >= 500 || !(sms.content.length !== 0) || loading || isAiLoading"
          size="sm"
          @click="onCreateSms"
        >
          {{ !useGeneratedAIMessage ? okBtn : $t('SendAnAIMessage') }}
        </b-button>
      </div>
    </div>
    <!--    Footer-->

    <sw-info-overlay
      :name="`${$t('MessageIsBeingGenerated')}…`"
      :show="isAiLoading"
    >
      <template #icon>
        <b-spinner
          variant="primary"
          small
          style="margin-top: .3rem"
        />
      </template>
    </sw-info-overlay>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { BFormTextarea } from 'bootstrap-vue'
import {
  ADD_MODAL,
  CLOSE_MODAL,
  CREATE_SMS,
  GEN_OPENAI_RESPONSE_V35,
  GET_OPEN_AI_CONFIG_ALL,
  GET_SELECT_USERS,
  GET_TEMPLATES,
  SET_MODAL_DATA,
  SHOW_MODAL,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { THREADS_SEARCH } from '@/@constants/fields'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'SmsModal',
  components: {
    vSelect,
    BFormTextarea,
    flatPickr,
  },
  props: ['okBtn'],
  data: () => ({
    isAiLoading: false,
    smsConfig: false,

    typingTime: 10,

    users: [],
    contacts: [],
    ranks: [],
    sms: {
      sentTo: [],
      content: '',
      smsSender: null,
      share: [],
      template: '',
      scheduledAt: '',
    },
    options: {
      smsTemplates: [],
    },
    selectedThread: null,
    loading: false,

    smsSenders: [],

    contactThreadList: [],
    contactThread: null,

    useGeneratedAIMessage: false,

    aiSource: 'SMS',
    aiMessage: '',
    aiResponse: '',
    aiResponseTyped: '',
    totalTokens: 0,

    aiModal: null,
  }),
  computed: {
    userOptions: {
      get() {
        return this.contactThread ? [
          ...this.contactThread.assignedUsers.map(item => ({ ...item, type: 'u' })),
          ...this.contactThread.contactThreadAssignedContacts.map(item => ({ ...item.contact, type: 'c' })),
          { ...this.contactThread.contact, type: 'c' },
        ] : []
      },
      set() {},
    },
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      system: 'system/getSettings',
      AI_PROMPTS_SMS: 'ai/getPrompts.SMS',
      AI_TYPE_SMS: 'ai/getType.SMS',
    }),
  },
  mounted() {
    if (this.modalConfig.allowThreads?.length) {
      // eslint-disable-next-line prefer-destructuring
      this.contactThread = this.modalConfig.allowThreads[0]
    }

    if (this.modalConfig.data) {
      // eslint-disable-next-line prefer-destructuring
      this.sms.sentTo = this.modalConfig.data?.sendTo || []
    }

    if (this.$store.getters['singleContact/getSelectedThread']) {
      this.contactThread = this.$store.getters['singleContact/getSelectedThread']
    } else if (this.modalConfig && this.modalConfig.thread) {
      this.contactThread = this.modalConfig.thread
    }

    if (!this.threadId && !this.modalConfig.threadId) {
      this.loadThreads('', loading => { this.isMeLoading = loading })
    } else {
      this.loadThread(this.threadId || this.modalConfig.threadId, loading => { this.isMeLoading = loading })
    }

    this.$store.dispatch('ranks/GET_RANKS_LIST')
      .then(() => {
        this.ranks = this.$store.getters['ranks/getRanksList']
      })

    if (this.checkRequiredModule('chatGptModule') && this.checkRequiredPermissions([this.$roles.CHAT_GPT_USE])) {
      this.$store.dispatch(`ai/${GET_OPEN_AI_CONFIG_ALL}`)
        .then(res => {
          const smsConfig = res.find(i => i.type === 'SMS')
          if (smsConfig) { this.smsConfig = smsConfig }
        })
    }

    this.loadTemplates()
    this.loadSmsSenders()

    this.$store.dispatch(`swModal/${ADD_MODAL}`, {
      title: this.$i18n.t('ReportAIResponse'),
      component: 'm-ai-report',
      data: { content: this.aiResponse },
      hideFooter: true,
    }).then(id => this.$set(this, 'aiModal', id))
  },
  methods: {
    async loadSmsSenders() {
      try {
        const resp = await axiosIns.get('1/settings/smsSender', { params: { fields_load: this.$fields.BASE_FIELDS } })

        if (resp?.data?.data?.items) this.smsSenders = resp.data.data.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    aiGeneration() {
      this.isAiLoading = true

      this.aiResponseTyped = ''

      if (this.sms.content) {
        this.getAiResponse(this.sms.content)
      } else {
        this.isAiLoading = false
      }
    },

    async getAiResponse(prompt) {
      this.isAiLoading = true

      if (prompt) {
        const payload = {
          system: this.AI_TYPE_SMS,
          content: JSON.parse(JSON.stringify(prompt)),
          source: this.aiSource,
          contactThread: this.getObjectId(this.contactThread),
        }

        this.$store.dispatch(`ai/${GEN_OPENAI_RESPONSE_V35}`, payload)
          .then(({ resultText, totalTokens }) => {
            this.aiResponse = resultText
            this.totalTokens = totalTokens

            this.typeText()
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
          })
          .finally(() => {
            this.isAiLoading = false
          })
      } else {
        this.isAiLoading = false
      }
    },

    typeText() {
      let currentIndex = 0

      this.showCursor = true

      const typingInterval = setInterval(() => {
        this.aiResponseTyped += this.aiResponse[currentIndex]
        currentIndex += 1

        if (currentIndex === this.aiResponse.length) {
          clearInterval(typingInterval)

          setTimeout(() => {
            this.isAiLoading = false
          }, 200)
        }
      }, this.typingTime)
    },

    changeTemplate(content) {
      this.sms.content = content
    },
    loadTemplates() {
      this.$store.dispatch(`templates/${GET_TEMPLATES}`, { type: 'sms' })
        .then(res => {
          this.options.smsTemplates = res.data.items
        })
    },
    onModalClose() {
      this.$emit('close')
    },
    changeAssignedUser(payload) {
      if (payload) this.sms.sentTo = [...payload]
      else this.sms.sentTo = []
    },
    onCreateSms() {
      this.loading = true

      const { contactThread, useGeneratedAIMessage, aiResponse } = this

      const smsData = {
        content: !useGeneratedAIMessage ? this.sms.content : aiResponse,
        sharedRanks: this.sms.share.map(rank => this.getId(rank)),
        sentTo: [],
        sentToContacts: [],
        scheduledAt: this.sms.scheduledAt ? this.sms.scheduledAt : null,
        smsSender: this.sms.smsSender ? this.getObjectId(this.sms.smsSender) : null,
      }

      const threadId = contactThread.id

      const contacts = this.sms.sentTo.filter(user => user.type === 'c')
      const users = this.sms.sentTo.filter(user => user.type === 'u')

      smsData.sentTo = users.map(item => this.getId(item))
      smsData.sentToContacts = contacts.map(item => this.getId(item))

      this.$store.dispatch(`modal/${CREATE_SMS}`, { smsData, threadId, contactId: this.contactThread.contact.id })
        .then(() => {
          this.loading = false
          this.showToast('success', this.$i18n.t('SendedSuccessfully'))

          this.$nextTick(() => this.close())
        })
        .catch(err => {
          this.loading = false
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    onSearch(search, loading) {
      loading(true)
      if (search.length) {
        this.userOptions = []

        Promise.all([this.getUsers(search), this.getContacts(search)])
          .then(res => {
            this.users = res[0].map(item => ({ ...item, type: 'u' }))
            this.contacts = res[1].map(item => ({ ...item, type: 'c' }))
            loading(false)
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
            loading(false)
          })
      } else loading(false)
    },
    getUsers(search) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`users/${GET_SELECT_USERS}`, search)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getContacts(search) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('contacts/GET_SELECT_CONTACTS', { search })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getId(field) {
      // eslint-disable-next-line no-nested-ternary
      return typeof field === 'object' ? (field?.id ? field.id : '') : field
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id) {
      const thread = await axiosIns(`1/contacts/threads/${id}`, { params: { fields_load: THREADS_SEARCH } })
      if (thread.data.data.item) {
        this.contactThread = thread.data.data.item
      }
    },

    openReportModal() {
      this.$store.dispatch(`swModal/${SET_MODAL_DATA}`, {
        id: this.aiModal,
        data: {
          content: this.aiResponse,
          source: this.aiSource,
        },
      })
        .then(() => this.$store.dispatch(`swModal/${SHOW_MODAL}`, this.aiModal))
    },
  },
}
</script>

<style scoped>
.prompt-carousel { margin-bottom: -1.1rem }

.prompt-carousel > div { padding-bottom: 1.1rem }

.prompt-carousel::before,
.prompt-carousel::after {
  content: '';

  position: absolute;

  top : 0;
  left: 0;

  height: 100%;
  width: 1.5rem;

  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}

.prompt-carousel::after {
  left: auto;
  right: 0;

  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}
</style>
