<template>
  <div
    v-if="modalConfig.data"
    class="d-flex flex-column pt-1"
    :class="
      modalConfig.data.contactThreadTaskType
        && modalConfig.data.contactThreadTaskType.isReportRequired
        && modalConfig.data.status
        && modalConfig.data.status === 'O'
        && (
          checkRequiredPermissions([
            $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ALL,
            $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED,
          ]
          )
          || (
            checkRequiredPermissions([
              $roles.CONTACT_THREAD_TASK_EDIT,
              { role: $roles.CONTACT_THREAD_TASK_EDIT_SELF, data: modalConfig.data, field: 'createdBy', userId: currentUser.id },
            ]) && (modalConfig.data.createdAt && isTimeLimitReached(modalConfig.data.createdAt.date, null, 'contactThreadTaskDeleteTime'))
          )
          || (
            checkRequiredPermissions([
              $roles.CONTACT_THREAD_TASK_DELETE,
            ])
          )
        )
        ? 'pb-25'
        : 'pb-1'
    "
    style="row-gap: 1rem"
  >
    <!--    START:Title and infos    -->
    <b-row>
      <b-col
        cols="1"
        class="mt-50"
      >
        <div
          v-b-tooltip.hover="{ variant: getEventVariant() }"
          :class="`bg-${getEventVariant()}`"
          style="width: 1rem; height: 1rem; border-radius: .2rem;"
          :title="$t(`task.status.${modalConfig.data.status}`)"
        />
      </b-col>

      <b-col
        class="d-flex flex-column align-items-start"
        style="row-gap: .34rem"
      >
        <!--    START:Title and dates    -->
        <div>
          <h4 v-if="modalConfig.data.contactThreadTaskType"
              class="mb-0"
          >
            {{ modalConfig.data.contactThreadTaskType.name }}
          </h4>

          <div v-if="modalConfig.data.startAt && modalConfig.data.endAt">
            {{ getDate(modalConfig.data.startAt.date.split('.')[0], modalConfig.data.endAt.date.split('.')[0]) }}
          </div>
        </div>
        <!--    END:Title and dates    -->

        <!--    START:About task    -->
        <div
          class="d-flex flex-wrap"
          style="column-gap: .34rem; row-gap: .14rem;"
        >
          <b-badge
            v-if="modalConfig.data.status"
            v-b-tooltip.hover="{ variant: getEventVariant() }"
            :variant="getEventVariant()"
            :title="$t('TaskStatus')"
          >
            {{ $t(`task.status.${modalConfig.data.status}`) }}
          </b-badge>

          <b-badge
            v-if="modalConfig.data.contactThreadTaskType"
            v-b-tooltip.hover.v-secondary
            variant="secondary"
            :title="$t('TaskType')"
          >
            {{ modalConfig.data.contactThreadTaskType.name }}
          </b-badge>
        </div>
        <!--    END:About task    -->

        <!--    START:Contact and Thread    -->
        <b-link
          v-if="modalConfig.data.contactThread && modalConfig.data.contactThread.contact"
          :href="`contact/${modalConfig.data.contactThread.contact.id}/thread/${modalConfig.data.contactThread.id}/timeline`"
          target="_blank"
        >
          <b-badge
            v-if="modalConfig.data.contactThread"
            variant="light-primary"
            class="text-wrap d-flex flex-wrap align-items-center"
            style="column-gap: .24rem; row-gap: .14rem; text-align: start;"
          >
            <div
              class="d-flex align-items-center"
              style="column-gap: .24rem; row-gap: .14rem; text-align: start;"
            >
              <feather-icon
                icon="LinkIcon"
                style="min-width: max-content;"
              />

              {{ `${modalConfig.data.contactThread.contact.firstName} ${modalConfig.data.contactThread.contact.lastName}` }}
            </div>

            <div
              class="d-flex align-items-center"
              style="column-gap: .24rem; row-gap: .14rem; text-align: start;"
            >
              <feather-icon
                icon="ArrowRightIcon"
                style="min-width: max-content;"
              />

              {{ modalConfig.data.contactThread.name }}
            </div>
          </b-badge>
        </b-link>
        <!--    END:Contact and Thread    -->
      </b-col>
    </b-row>
    <!--    END:Title and infos    -->

    <!--    START:Content (HTML)    -->
    <b-row v-if="modalConfig.data.content">
      <b-col
        cols="1"
        class="mt-50"
      >
        <feather-icon
          icon="AlignLeftIcon"
          size="18"
        />
      </b-col>

      <b-col>
        <p v-html="(modalConfig.data.content || modalConfig.data.contentHtml || '').replaceAll('\n', '<br>')" />
      </b-col>
    </b-row>
    <!--    END:Content (HTML)    -->

    <!--    START:Address    -->
    <b-row v-if="modalConfig.data.address">
      <b-col
        cols="1"
        class="mt-50"
      >
        <feather-icon
          icon="MapPinIcon"
          size="18"
        />
      </b-col>

      <b-col>
        {{ modalConfig.data.address }}
      </b-col>
    </b-row>
    <!--    END:Address    -->

    <!--    START:Users info    -->
    <b-row v-if="modalConfig.data.createdBy || modalConfig.data.assignedUser">
      <b-col
        cols="1"
        class="mt-50"
      >
        <feather-icon
          icon="UsersIcon"
          size="18"
        />
      </b-col>

      <b-col>
        <b-row style="row-gap: .34rem">
          <b-col
            v-if="modalConfig.data.createdBy"
            :md="modalConfig.data.assignedUser ? 6 : 12"
            class="d-flex flex-column"
            style="column-gap: .5rem; row-gap: .14rem"
          >
            <strong>{{ $t('CreatedBy') }}:</strong>

            <avatar
              :user="modalConfig.data.createdBy"
              :show-name="isMobile"
              show-tooltip
              text-variant="text-dark"
              size="1.24rem"
            />
          </b-col>

          <b-col
            v-if="modalConfig.data.assignedUser"
            :md="modalConfig.data.createdBy ? 6 : 12"
            class="d-flex flex-column"
            style="column-gap: .5rem; row-gap: .14rem"
          >
            <strong>{{ $t('AssignedUser') }}:</strong>

            <avatar
              :user="modalConfig.data.assignedUser"
              :show-name="isMobile"
              show-tooltip
              text-variant="text-dark"
              size="1.24rem"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--    END:Users info    -->

    <!--    START:Reminders    -->
    <b-row v-if="modalConfig.data.sendReminderEmail || modalConfig.data.sendReminderSms">
      <b-col
        cols="1"
        class="mt-50"
      >
        <feather-icon
          icon="AlertCircleIcon"
          size="18"
        />
      </b-col>

      <b-col>
        <b-row style="row-gap: .34rem">
          <b-col
            md="6"
            class="d-flex flex-column align-items-start"
            style="column-gap: .5rem; row-gap: .14rem"
          >
            <strong>{{ $t('ReminderEmail') }}:</strong>

            <b-badge :variant="modalConfig.data.sendReminderEmail ? 'success' : 'light-secondary'">
              {{ $t(modalConfig.data.sendReminderEmail ? 'Active2' : 'NotActive2') }}
            </b-badge>
          </b-col>

          <b-col
            md="6"
            class="d-flex flex-column align-items-start"
            style="column-gap: .5rem; row-gap: .14rem"
          >
            <strong>{{ $t('ReminderSms') }}:</strong>

            <b-badge :variant="modalConfig.data.sendReminderSms ? 'success' : 'light-secondary'">
              {{ $t(modalConfig.data.sendReminderSms ? 'Active2' : 'NotActive2') }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--    END:Reminders    -->

    <!--    START:Files    -->
    <b-row
      v-if="modalConfig.data.files.length"
    >
      <b-col
        cols="1"
        class="mt-50"
      >
        <feather-icon
          icon="PaperclipIcon"
          size="18"
        />
      </b-col>

      <b-col>
        <documents-list :documents="modalConfig.data.files" />
      </b-col>
    </b-row>
    <!--    END:Files    -->

    <!--    START:Section Bottom    -->
    <!--    END:Section Bottom    -->

    <!-- Checklist -->
    <div v-if="modalConfig.data.contactThreadChecklistPointDatum">
      <div class="d-flex flex-wrap align-items-center pt-50">
        <div>
          <b-form-checkbox
            disabled
            :checked="modalConfig.data.checked"
          >
            <span>
              {{ modalConfig.data.contactThreadChecklistPointDatum.contactThreadChecklistTypePoint.name }}
              {{ modalConfig.data.checked && modalConfig.data.doneAt && modalConfig.data.doneAt.date ? `- ${modalConfig.data.doneAt.date.split('.')[0]}` : '' }}
            </span>
          </b-form-checkbox>
        </div>
        <div class="pl-1">
          <b-avatar
            v-b-tooltip.hover
            :src="modalConfig.data.createdBy.avatar"
            :title="`${modalConfig.data.createdBy.firstName} ${modalConfig.data.createdBy.lastName}`"
          />
        </div>
      </div>
    </div>

    <!--    START:Tools    -->
    <!--          && modalConfig.data.contactThreadTaskType.isReportRequired-->
    <div
      v-if="
        modalConfig.data.contactThreadTaskType
          && modalConfig.data.status
          && modalConfig.data.status === 'O'
          && (
            checkRequiredPermissions([
              $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ALL,
              $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED,
            ]
            )
            || (
              checkRequiredPermissions([
                $roles.CONTACT_THREAD_TASK_EDIT,
                { role: $roles.CONTACT_THREAD_TASK_EDIT_SELF, data: modalConfig.data, field: 'createdBy', userId: currentUser.id },
              ]) && (modalConfig.data.createdAt && isTimeLimitReached(modalConfig.data.createdAt.date, null, 'contactThreadTaskDeleteTime'))
            )
            || (
              checkRequiredPermissions([
                $roles.CONTACT_THREAD_TASK_DELETE,
              ])
            )
          )
      "
      class="d-flex flex-wrap-reverse justify-content-between"
      style="row-gap: .5rem; column-gap: .5rem;"
    >
      <div
        v-if="
          checkRequiredPermissions([
            $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ALL,
            $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED,
          ])
        "
        class="d-flex flex-wrap align-items-center"
        style="row-gap: .34rem; column-gap: .34rem;"
      >
        <b-button
          v-b-tooltip.hover.v-success
          :title="$t('MarkAsDone')"
          variant="flat-success"
          size="sm"
          class="px-75 py-50"
          @click="changeTaskStatus(modalConfig.data.id, 'D')"
        >
          <feather-icon icon="CheckIcon" />

          {{ $t('task.status.D') }}
        </b-button>

        <b-button
          v-b-tooltip.hover.v-danger
          :title="$t('MarkAsNotDone')"
          variant="flat-danger"
          size="sm"
          class="px-75 py-50"
          @click="changeTaskStatus(modalConfig.data.id, 'ND')"
        >
          <feather-icon icon="XIcon" />

          {{ $t('task.status.ND') }}
        </b-button>
      </div>

      <div
        class="d-flex flex-wrap align-items-center"
        style="row-gap: .1rem; column-gap: .1rem;"
      >
        <b-button
          v-if="checkRequiredPermissions([
            $roles.CONTACT_THREAD_TASK_EDIT,
            { role: $roles.CONTACT_THREAD_TASK_EDIT_SELF, data: modalConfig.data, field: 'createdBy', userId: currentUser.id },
          ]) && (modalConfig.data.createdAt && isTimeLimitReached(modalConfig.data.createdAt.date, null, 'contactThreadTaskDeleteTime'))"
          variant="flat-warning"
          size="sm"
          class="btn-icon rounded-circle"
          @click="editTask(modalConfig.data)"
        >
          <feather-icon
            icon="Edit2Icon"
            size="18"
          />
        </b-button>

        <b-button
          v-if="checkRequiredPermissions([
            $roles.CONTACT_THREAD_TASK_DELETE,
          ])"
          variant="flat-danger"
          size="sm"
          class="btn-icon rounded-circle"
          @click="deleteTask(modalConfig.data.id, modalConfig.data.contactThread)"
        >
          <feather-icon
            icon="TrashIcon"
            size="18"
          />
        </b-button>
      </div>
    </div>
    <!--    END:Tools    -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DocumentsList from '@/views/components/DocumentsList.vue'
import { VBTooltip } from 'bootstrap-vue'
import {
  CLOSE_MODAL,
  DELETE_CONTACT_TASK, OPEN_MODAL, RELOAD_CONTENT, UPDATE_CONTACT_TASK,
} from '@/@constants/mutations'
import * as roles from '@/helpers/permissions'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import useSwUtils from '@/composable/useSwUtils'

export default {
  name: 'ViewModal',
  // eslint-disable-next-line vue/require-prop-types
  components: {
    DocumentsList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const { isTimeLimitReached } = useSwUtils()
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isTimeLimitReached,
      isMobile,
    }
  },
  data: () => ({
    roles,
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  methods: {
    getEventVariant() {
      switch (this.modalConfig.data.status) {
        case 'O': return 'info'
        case 'D': return 'success'
        case 'ND': return 'danger'
        default: return 'primary'
      }
    },
    getDate(startDate, endDate) {
      const start = moment(startDate)
      const end = moment(endDate)

      const diff = end.endOf('hour').diff(start.startOf('hour'), 'hours')

      // If whole day
      if (diff === 23) return `${moment(startDate).format('YYYY-MM-DD')} ⋅ ${this.$i18n.t('WholeDay')}`

      // If not whole day
      if (diff < 23) return `${moment(startDate).format('YYYY-MM-DD')} ⋅ ${moment(startDate).format('HH:mm')} - ${moment(endDate).format('HH:mm')}`

      // Else
      return `${moment(startDate).format('YYYY-MM-DD HH:mm')} - ${moment(endDate).format('YYYY-MM-DD HH:mm')}`
    },

    formatDate,
    taskTypeVariant(value) {
      let variant = 'light-primary'
      if (value === 'D') variant = 'light-success'
      else if (value === 'O') variant = 'light-info'
      else if (value === 'ND') variant = 'light-danger'
      return variant
    },
    onModalClose() {
      this.$emit('close')
    },
    onModalContentReload(payload) { this.$store.commit(`modal/${RELOAD_CONTENT}`, payload) },
    changeTaskStatus(taskId, statusValue) {
      const threadId = this.modalConfig.data.contactThread?.id ?? this.modalConfig.threadId
      const data = {
        id: this.modalConfig.data.id.toString(),
        status: statusValue,
      }
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (this.modalConfig.data.contactThreadTaskType.isReportRequired) {
            this.$store.commit(`modal/${OPEN_MODAL}`,
              {
                modalType: 'report',
                modalTitle: this.$i18n.t('NewReport'),
                okButtonTitle: this.$i18n.t('AddReport'),
                threadFor: threadId,
                data: { ...this.modalConfig.data, statusValue },
              })
          } else {
            this.$store.dispatch(`singleContact/${UPDATE_CONTACT_TASK}`, { updateTaskData: data, threadId })
              .then(res => {
                // this.reloadContent(taskId).then(() => this.onModalClose())
                this.onModalClose()
                this.onModalContentReload(res)
                this.showToast('success', this.$i18n.t('StatusUpdated'))
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
              })
          }
        })
        .catch(() => { })
    },

    async modalClose() { return this.$store.commit(`modal/${CLOSE_MODAL}`) },
    async modalReloadContent(payload) { return this.$store.commit(`modal/${RELOAD_CONTENT}`, payload) },

    editTask(task, repeat = false) {
      const taskData = task
      if (repeat) delete taskData.id

      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'task',
          size: 'lg',
          modalTitle: this.$i18n.t('EditTask'),
          okButtonTitle: this.$i18n.t('Save'),
          threadFor: task.contactThread && task.contactThread.id,
          editedData: taskData,
        })
    },
    deleteTask(taskId, thread) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(async () => {
          try {
            await this.$store.dispatch(`singleContact/${DELETE_CONTACT_TASK}`, { taskId, threadId: thread?.id })

            await this.modalReloadContent({ id: taskId })
            await this.modalClose()

            this.showToast('success', this.$i18n.t('TaskRemoved'))
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
          // this.$store.dispatch(`singleContact/${DELETE_CONTACT_TASK}`, { taskId, threadId: thread.id })
          //   .then(() => {
          //     // this.tasks.items.splice(taskIndex, 1)
          //     this.modalReloadContent({ id: taskId }).then(() => this.modalClose())
          //
          //     this.showToast('success', this.$i18n.t('TaskRemoved'))
          //   })
          //   .catch(() => {
          //     this.showToast('danger', this.$i18n.t('ProblemOccured'))
          //   })
        })
        .catch(() => { })
    },
  },
}
</script>
